import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isMobile } from '../../utils/elementUtils';
import bem from '../../utils/bem-cn';

const b = bem('atoz');
const s = bem('search-results');

export default class AtoZ extends Component {
  state = {
    containerWidth: 0,
    origLetters: this.props.letters || null,
    letters: this.props.letters || null,
    hiddenLetters: [],
    toggleMore: false
  };

  static propTypes = {
    containerWidth: PropTypes.number,
    handleLetterClear: PropTypes.func.isRequired,
    handleLetterSelection: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    letters: PropTypes.object.isRequired
  };

  listRef = React.createRef();

  // Classes
  componentClass = b();
  listButtonClass = b('list-button');
  moreButtonClass = b('more-button').mix([bem('link'), s('content-title-button')]);

  howManyLetters = () => {
    const containerWidth = this.state.containerWidth;

    if (!this.listRef.current) {
      return;
    }

    const widthsArray = Array.from(this.listRef.current.children).map(item => item.getBoundingClientRect().width);

    // return the index position of the first letter that doesn't fit within the container width
    // margin-left of container is -15px, hence the '-15' below
    let total = 0;

    for (let i = 0; i < widthsArray.length; i++) {
      if (total + widthsArray[i] - 15 > containerWidth) {
        return i;
      } else {
        total += widthsArray[i];
      }
    }
  };

  updateAlphabetList = () => {
    const arrayAmount = this.howManyLetters();
    const letters = {...this.state.origLetters};
    let hiddenLetters = [];

    if (arrayAmount) {
      hiddenLetters = Object.keys(this.state.origLetters).slice(arrayAmount);
      !hiddenLetters.includes('#') && hiddenLetters.push('#');
      hiddenLetters.forEach(letter => {
        delete letters[letter];
      });
    }

    this.setState({ letters, hiddenLetters });
  };

  toggleMore = () => {
    this.setState((prevState) => ({toggleMore: !prevState.toggleMore}));
    const focusable = (item) => !item.children[0].hasAttribute('disabled');
    // find the first focusable element so that focus doesn't stay on more/less button
    const firstFocusable = Array.from(this.listRef.current.children).find(focusable);
    firstFocusable.children[0].focus();
  };

  updateContainer = () => {
    const smallContainerWidth = this.props.containerWidth * 0.25; // containerWidth is width of viewport, this takes into account 25/75 layout for tablet/desktop
    const containerWidth = isMobile()
      ? this.props.containerWidth
      : smallContainerWidth > 250
        ? 250
        : smallContainerWidth;
    this.setState({containerWidth: containerWidth}, this.updateAlphabetList);
  };

  componentDidMount () {
    this.updateContainer();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.containerWidth !== this.props.containerWidth) {
      this.updateContainer();
    }

    if (prevProps.letters !== this.props.letters) {
      this.setState({origLetters: this.props.letters});
    }
  }

  render () {
    const buttonText = this.state.toggleMore ? <FormattedMessage id='AtoZ.button.less' defaultMessage='less' /> : <FormattedMessage id='AtoZ.button.more' defaultMessage='more' />;

    return (
      <div className={this.componentClass}>
        <h3 className={b('heading')}>
          <FormattedMessage
            id='AtoZ.heading.alphabet'
            defaultMessage='Alphabet'
          />
        </h3>
        <ul className={b('list', {'is-open': this.state.toggleMore})} ref={this.listRef}>
          {this.state.letters
            ? <>
              {Object.keys(this.state.letters).map((letter, index) =>
                <li key={index} className={b('list-item')}>
                  {this.state.origLetters[letter] > 0
                    ? (
                      <button
                        key={letter}
                        className={this.listButtonClass}
                        onClick={this.props.handleLetterSelection.bind(null, letter)}
                        aria-current={this.props.selectedLetter === letter}
                      >
                        {letter}
                      </button>
                    ) : (
                      <button key={letter} className={this.listButtonClass} disabled>{letter}</button>)}
                </li>
              )}
              {this.state.hiddenLetters.map((letter, index) =>
                <li
                  key={index}
                  className={b('list-item', {hidden: !this.state.toggleMore})}
                  aria-hidden={!this.state.toggleMore}
                >
                  {this.state.origLetters[letter] > 0
                    ? (
                      <button
                        key={letter}
                        className={this.listButtonClass}
                        onClick={this.props.handleLetterSelection.bind(null, letter)}
                        aria-current={this.props.selectedLetter === letter}
                        disabled={!this.state.toggleMore}
                      >
                        {letter}
                      </button>
                    ) : (
                      <button key={letter} className={this.listButtonClass} disabled>{letter}</button>
                    )}
                </li>
              )}
            </>
            : null}
        </ul>
        {this.state.hiddenLetters.length > 0 &&
          <button
            className={this.moreButtonClass}
            onClick={this.toggleMore}
          >{buttonText}</button>}
        {this.props.selectedLetter ? <span className={b('list-clear')}>|<button
          className={b('list-clear-button').mix('c-ckm-link')} onClick={this.props.handleLetterClear}
                                                                        >
          <FormattedMessage
            id='AtoZ.button.reset' defaultMessage='reset'
          /></button></span> : null}
      </div>
    );
  }
}

AtoZ.displayName = 'AtoZ';
