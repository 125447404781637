import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import useIntl from '../../hooks/use-intl';
import { Button } from '@els/els-react--button';
import { Icon } from '@els/els-react--icon';
import { Flyout } from '@els/els-react--flyout';
import { Dropdown } from '@els/els-react--dropdown';
import { IMAGE_VIEWS, MODEL_STATE } from '../../constants';
import { isXSmall, useIntersection, isMobile } from '../../utils/elementUtils';
import MediaViewNavigation from '../../components/common/MediaViewNavigation';
import MediaInlineModel from '../../components/common/MediaInlineModel';
import InfoMessage from '../../components/common/InfoMessage';
import {trackEvent} from '../../utils/eventTrackUtils';
import { Loader } from '@els/els-react--loader';

import { MainContext } from '../../context/main-context';
import useShowModelRef from '../../hooks/useShowModelRef';

import '../../assets/img/icon_compare.svg';
import '../../assets/img/labels-on.svg';
import '../../assets/img/labels-off.svg';
import { pluck } from 'ramda';

const messages = defineMessages({
  compare3DButtonLabel: {
    id: 'readerImage.compare3DButtonLabel',
    defaultMessage: 'Compare with 3D'
  },
  compare2DButtonLabel: {
    id: 'readerImage.compare2DButtonLabel',
    defaultMessage: 'Compare with 2D'
  },
  informationButtonLabel: {
    id: 'readerImage.informationButtonLabel',
    defaultMessage: 'Information'
  },
  expandButtonLabel: {
    id: 'readerImage.expandButtonLabel',
    defaultMessage: 'Expand'
  },
  compareButtonFlyout: {
    id: 'readerImage.compareButtonFlyout',
    defaultMessage: 'Display 2D and 3D images side by side in fullscreen'
  },
  refreshButtonFlyout: {
    id: 'readerImage.refreshButtonFlyout',
    defaultMessage: 'Refresh 3D model'
  },
  labelSelectDropdownLabel: {
    id: 'readerImage.labelSelectDropdownLabel',
    defaultMessage: 'Show all labels'
  },
  labelSelectDropdownOption: {
    id: 'readerImage.labelSelectDropdownOption',
    defaultMessage: 'Label view'
  },
  labelSelectViewDropdownLabel: {
    id: 'readerImage.labelSelectLablelView',
    defaultMessage: 'Select view'
  }
});

const Image = ({isLazyLoadImages = true, ...props}) => {
  const [screenIds, setScreenIds] = useState(undefined);
  const intl = useIntl();
  const ref = useRef();
  const inViewPort = useIntersection(ref, '400px 0px 400px 0px');
  const context = useContext(MainContext);
  const [modelLabelsVisible, setModelLabelsVisible] = useState(true);
  const [modelState, setModelState] = useState(MODEL_STATE.LOADING);
  const [currentView, setCurrentView] = useState(IMAGE_VIEWS.IMAGE);
  const [currentScreenId, setCurrentScreenId] = useState(null);
  const [imageSize, setImageSize] = useState({height: '', width: ''});
  const [hasDownloadedScreens, setHasDownloadedScreens] = useState(false);
  const [showImage, setShowImage] = useState(window.location.hash === `#${props.id}` || !isLazyLoadImages);
  const [hasDownloadedImage, setHasDownloadedImage] = useState(window.location.hash === `#${props.id}`);
  const compareButtonLabel = currentView === IMAGE_VIEWS.IMAGE ? intl.formatMessage(messages.compare3DButtonLabel) : intl.formatMessage(messages.compare2DButtonLabel);

  const defaultLabelView = intl.formatMessage(messages.labelSelectDropdownLabel);
  const [currentLabelView, setCurrentLabelView] = useState({ view: defaultLabelView, value: null });
  const { screenData } = props;

  useEffect(() => {
    // Only call if user has complete anatomy access
    // TODO - how can we limit this to images with known 3d screens?
    if (context.user.hasCompleteAnatomyAccess() && props.downloadCAScreens !== undefined) {
      if (screenData) {
        setScreenIds(pluck('id', screenData));
        setModelLabelsVisible(!props.returnScreenLabelViews());
      }
    }
  }, [screenData]);

  const handleThumbnailSelect = (viewSelected, screenId) => {
    if (currentView === IMAGE_VIEWS.IMAGE) {
      // Determine model frame size here, once the 2D image has had sufficient time to load
      const image = document.getElementById(props.id);
      setImageSize({
        height: image.parentElement.offsetHeight,
        width: image.parentElement.offsetWidth
      });
    }
    setModelState(MODEL_STATE.LOADING);
    setCurrentView(viewSelected);
    setCurrentScreenId(screenId);
    setModelLabelsVisible(!props.returnScreenLabelViews());

    const initialCanvas = document.getElementById('canvas0'); // return canvas back to body
    initialCanvas.style.display = 'none';
    document.body.appendChild(initialCanvas);
  };

  // Trigger screen download when CA-capable image comes into view while scrolling
  if (screenData && inViewPort && !hasDownloadedScreens) {
    console.log('%ctrigger download for', 'color:green', screenIds);
    props.downloadCAScreens(screenIds);
    setHasDownloadedScreens(true);
  }

  // Trigger image download when image comes into view while scrolling
  if (inViewPort && !hasDownloadedImage) {
    setShowImage(true);
    setHasDownloadedImage(true);
  }

  const imageTag = () => {
    if (showImage) {
      return (
        <img
          src={props.src}
          id={props.id}
          data-image-zoom={props['data-image-zoom']}
          data-model-screen-ids={screenIds}
          data-type={currentView}
          alt={props.alt}
          aria-label={props.alt}
          loading={props.loading}
        />
      );
    } else {
      return (<Loader isBlocking={false} id={props.id} />);
    }
  };

  const selectLabelView = (evt, view) => {
    if (view === null) {
      props.showModelRef(`canvas-parent-inline-${props.id}`, undefined, undefined, undefined, undefined, true);
    } else {
      props.updateLabelViewRef(view);
    }
    setCurrentLabelView({ view: evt.label, value: view });
    setModelLabelsVisible(true);
  };

  const modelHeaderOptions = () => {
    const screenLabelViews = props.returnScreenLabelViews();
    if (screenLabelViews?.length) {
      screenLabelViews.splice(-1);
    }

    return (
      <>
        <Flyout
          className='o-els-flex-layout__item'
          trigger='hover'
          placement='bottom'
          theme='simple'
          flyout={intl.formatMessage(messages.refreshButtonFlyout)}
          shouldCloseOnESC
          id='refresh-btn-flyout'
        >
          <Button
            onClick={handleRefreshClick}
            type={Button.Types.LINK}
            className='c-ckm-reader-image__toolbar-button o-els-flex-layout__item'
            id='refresh-btn'
            data-testid='refresh-btn'
          >
            <Icon
              a11y={{ name: intl.formatMessage(messages.refreshButtonFlyout) }}
              sprite='Redo'
              className=''
              textAlignment='bottom'
              size={Icon.Sizes.XS}
              isVisible
              isTextFirst
              id='refresh-btn-icon'
            />
          </Button>
        </Flyout>
        <div className='o-els-flex-layout o-els-flex-layout__item o-els-flex-layout__item--grow'>
          <Flyout
            className=''
            trigger='hover'
            placement='bottom'
            theme='simple'
            flyout={
              <FormattedMessage
                id='readerImage.labelsButtonFlyout'
                defaultMessage='Turn labels {onOrOff}'
                values={{ onOrOff: modelLabelsVisible ? 'off' : 'on' }}
              />
        }
            shouldCloseOnESC
            id='labels-btn-flyout'
          >
            <Button
              onClick={handleLabelsClick}
              type={Button.Types.LINK}
              className='c-ckm-reader-image__toolbar-button o-els-flex-layout__item'
              id='labels-btn'
              data-testid='labels-btn'
            >
              <svg
                className='icon o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--bottom'
              >
                <use xlinkHref={`#labels-${modelLabelsVisible ? 'on' : 'off'}`} />
              </svg>
            </Button>
          </Flyout>
          {screenLabelViews &&
            <span className='u-els-margin-left'>
              <Dropdown
                id='label-view-dropdown'
                options={screenLabelViews.map((item, index) => {
                  const indexNumber = Number(index) + 1;
                  return (
                    {
                      label: intl.formatMessage(messages.labelSelectDropdownOption) + ' ' + indexNumber,
                      id: currentLabelView.value === item && 'activeLabel',
                      onClick: (evt) => { selectLabelView(evt, item); }
                    }
                  );
                })}
                label={intl.formatMessage(messages.labelSelectViewDropdownLabel)}
                className=''
                placement='bottom-start'
                theme='minimial'
              />
            </span>}
        </div>
      </>
    );
  };
  const handleCompareClick = (evt) => {
    trackEvent('buttonClick', {buttonType: 'model-thumbnail:COMPARE'}
    );
  };

  const handleExpandClick = (evt) => { };

  const handleLabelsClick = (evt) => {
    setModelLabelsVisible(!modelLabelsVisible);
    props.toggleModelLabels(!modelLabelsVisible);
    modelLabelsVisible ? setCurrentLabelView({ view: defaultLabelView, value: null }) : setCurrentLabelView({ view: intl.formatMessage(messages.labelSelectDropdownOption) + ' ' + 1, value: props.returnScreenLabelViews()[0] });
  };

  const handleRefreshClick = (evt) => {
    setModelLabelsVisible(!props.returnScreenLabelViews());
    setCurrentLabelView({ view: defaultLabelView, value: null });

    if (modelState === MODEL_STATE.PAUSED) {
      resumePausedModel();
    } else {
      setModelState(MODEL_STATE.LOADING);
      props.showModelRef(getCanvasParentId(), undefined, onDisplay, undefined, onInlineModelError);
    }
  };

  const getCanvasParentId = () => {
    return `canvas-parent-inline-${props.id}`;
  };

  const onDisplay = () => {
    setModelState(MODEL_STATE.DISPLAYED);
    setModelLabelsVisible(!props.returnScreenLabelViews());
  };

  const onPause = () => {
    setModelState(MODEL_STATE.PAUSED);
  };

  const onInlineModelError = () => {
    setModelState(MODEL_STATE.ERROR);
    console.error('Unsuccessful attempt to activate model');
  };

  const resumePausedModel = () => {
    setModelState(MODEL_STATE.LOADING);
    setModelLabelsVisible(!props.returnScreenLabelViews());
    props.showModelRef(getCanvasParentId(), currentScreenId, onDisplay, onPause, onInlineModelError);
  };

  useShowModelRef(currentView, currentScreenId, {showModelRef: props.showModelRef, getCanvasParentId, onDisplay, onPause, onInlineModelError});

  return (
    <div ref={ref}>
      {screenData
        ? <div className='c-ckm-reader-image'>
          <div className='c-ckm-reader-image__container'>
            {currentView === IMAGE_VIEWS.MODEL && isMobile() && <InfoMessage />}
            <div className='c-ckm-reader-image__toolbar o-els-flex-layout'>
              {currentView === IMAGE_VIEWS.MODEL && modelState !== MODEL_STATE.ERROR && modelHeaderOptions()}
              <Flyout
                className=''
                trigger='hover'
                placement='bottom'
                theme='simple'
                flyout={intl.formatMessage(messages.compareButtonFlyout)}
                shouldCloseOnESC
                id='compare-btn-flyout'
              >
                <Button
                  onClick={handleCompareClick}
                  type={Button.Types.LINK}
                  className='c-ckm-reader-image__toolbar-button c-ckm-reader-image__toolbar-button--compare o-els-flex-layout__item'
                  id='compare-btn'
                  data-image-zoom={props.id}
                  data-model-screen-ids={screenIds}
                  data-type='COMPARE'
                  data-aa-button='model:model-compare-2D-3D'
                >
                  <svg
                    className='icon o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--bottom'
                  >
                    <use xlinkHref='#icon_compare' />
                  </svg>
                  <span id='compare-btn-icon-label' className='icon__text u-els-margin-left-1o2'>{compareButtonLabel}</span>
                  <Icon
                    a11y={{ name: intl.formatMessage(messages.informationButtonLabel) }}
                    sprite={Icon.Sprites.INFORMATION_OUTLINE_CIRCLE}
                    className='u-els-margin-left-1o2'
                    textAlignment='bottom'
                    size={Icon.Sizes.XS}
                    isVisible
                    id='info-btn-icon'
                  />
                </Button>
              </Flyout>
              <div className='c-ckm-reader-image__toolbar-divider o-els-flex-layout__item' />

              <Button
                onClick={handleExpandClick}
                type={Button.Types.LINK}
                className='c-ckm-reader-image__toolbar-button o-els-flex-layout__item'
                id='expand-btn'
                data-image-zoom={props.id}
                data-model-screen-ids={screenIds}
                data-type={currentView}
              >
                <Icon
                  a11y={{ name: intl.formatMessage(messages.expandButtonLabel) }}
                  sprite='Expand'
                  className=''
                  textAlignment='bottom'
                  size={Icon.Sizes.XS}
                  isVisible
                  isTextFirst
                  id='expand-btn-icon'
                  data-image-zoom={props.id}
                  data-model-screen-ids={screenIds}
                  data-model-screen-id={currentScreenId}
                  data-type={currentView}
                >
                  {isXSmall() ? '' : intl.formatMessage(messages.expandButtonLabel)}
                </Icon>
              </Button>
            </div>
            <div className={`c-ckm-reader-image${currentView === IMAGE_VIEWS.MODEL ? '__model' : '__image'}`}>
              {currentView === IMAGE_VIEWS.IMAGE && imageTag()}
              {currentView === IMAGE_VIEWS.MODEL &&
                <MediaInlineModel
                  resumeModel={resumePausedModel}
                  modelState={modelState}
                  canvasParentId={getCanvasParentId()}
                  hideModelRef={props.hideModelRef}
                  frameDimensions={imageSize}
                  labelsVisible={modelLabelsVisible}
                  showLabels={!props.returnScreenLabelViews()}
                  viewChangeCallback={handleThumbnailSelect}
                  currentScreenId={currentScreenId}
                />}
            </div>
            <div className='c-ckm-reader-image__footer'>
              <MediaViewNavigation
                eid={props.id}
                screenIds={screenIds}
                screenData={screenData}
                imageSrc={props.src}
                viewChangeCallback={handleThumbnailSelect}
                views={[IMAGE_VIEWS.IMAGE, IMAGE_VIEWS.MODEL]}
                currentView={currentView}
                showSlider
                showImageThumbnail={showImage}
              />
            </div>
          </div></div>
        : imageTag()}
    </div>
  );
};

Image.displayName = 'Image';

Image.propTypes = {
  src: PropTypes.string,
  id: PropTypes.string,
  alt: PropTypes.string,
  loading: PropTypes.string,
  showModelRef: PropTypes.func,
  hideModelRef: PropTypes.func,
  screenLabelViews: PropTypes.array,
  hubEid: PropTypes.string
};

export default Image;
