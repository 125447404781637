import React, { Component } from 'react';
import CKApi from '../../services/api-helper';
import { videoPlayed } from '../../services/usageReporting';
import { defineMessages } from 'react-intl';
import { trackEvent } from '../../utils/eventTrackUtils';
import EVENTS from '../../constants/adobeEvents';

const messages = defineMessages({
  videoLoadingFailed: {
    id: 'VideoPlayer.error.videoLoading',
    defaultMessage: 'Video Loading Failed'
  }
});

const containerId = 'jwplayer-container';
const getPlayerConfig = (medResUrl) => [
  {
    type: 'html5',
    config:
    {
      file: medResUrl,
      provider: 'video'
    }
  }, {
    type: 'download',
    file: medResUrl
  }
];

export default class VideoPlayer extends Component {
  state = {
    playStarted: false,
    highResUrl: '',
    medResUrl: '',
    lowResUrl: '',
    thumbnailUrl: '',
    searchIndex: '',
    videoTitle: '',
    videoLoaded: false,
    withAnalytics: this.props.withAnalytics ?? true
  };

  playerRef = React.createRef();

  componentDidMount = () => {
    this.getVideoData(this.props.eid, this.props.videoUrl);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  };

  componentDidUpdate (prevProps) {
    if (this.props.eid !== prevProps.eid || this.props.videoUrl !== prevProps.videoUrl) {
      this.getVideoData(this.props.eid, this.props.videoUrl);
    }
  }

  componentWillUnmount = () => {
    if (this.state.videoLoaded) {
      this.handleBeforeUnload();

      jwplayer().remove();
    }

    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  };

  handleBeforeUnload = (event) => {
    if (event) {
      event.returnValue = '';
    }

    if (this.props.onCloseVideo) {
      this.props.onCloseVideo(this.getVideoPosition());
    }
  };

  getVideoData = (eid, videoUrl) => {
    // If videoUrl is passed, use it directly
    if (videoUrl) {
      this.setupVideo({data: { HIGH: {authenticatedStoreUrl: videoUrl}, MEDIUM: {authenticatedStoreUrl: videoUrl}, LOW: {authenticatedStoreUrl: videoUrl}, VIDEO_POSTER: null, CLOSED_CAPTIONING: null}});
      return;
    }
    if (this.props.type === 'anc') {
      CKApi.get(`/student/api/content/anc/${this.props.eid}`)
        .then(({data}) => {
          const videoURLData = {authenticatedStoreUrl: data};
          this.setupVideo({data: { HIGH: videoURLData, MEDIUM: videoURLData, LOW: videoURLData, VIDEO_POSTER: null, CLOSED_CAPTIONING: null}});
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      CKApi.get(`/student/api/content/video/${eid}`)
        .then(this.setupVideo)
        .catch(() => { this.setState({ errorMessage: this.props.intl.formatMessage(messages.videoLoadingFailed) }); });
    }
  };

  getVideoDuration = () => {
    return Math.round(jwplayer().getDuration());
  };

  getVideoPosition = () => {
    return Math.round(jwplayer().getPosition());
  };

  setupVideo = ({ data }) => {
    const { HIGH, MEDIUM, LOW, VIDEO_POSTER, CLOSED_CAPTIONING } = data;
    if (this.props.videoDetailsNeeded) this.props.getVideoDetails(VIDEO_POSTER);
    this.setState({
      highResUrl: HIGH.authenticatedStoreUrl,
      medResUrl: MEDIUM.authenticatedStoreUrl,
      lowResUrl: LOW.authenticatedStoreUrl,
      thumbnailUrl: VIDEO_POSTER?.authenticatedStoreUrl,
      searchIndex: HIGH?.searchIndex || 'GLOBAL',
      videoTitle: this.props.videoTitle || VIDEO_POSTER?.itemTitle,
      videoLoaded: true
    });

    const playerConfig = getPlayerConfig(MEDIUM.authenticatedStoreUrl);
    const closedCaptioning = CLOSED_CAPTIONING
      ? [{
        file: CLOSED_CAPTIONING.authenticatedStoreUrl,
        kind: 'captions'
      }] : null;

    const player = jwplayer(containerId);

    player.setup(
      {
        image: VIDEO_POSTER?.authenticatedStoreUrl,
        width: '98%',
        aspectratio: '16:9',
        title: 'VIDEO',
        bufferlength: 10,
        autostart: this.props.autostart ?? true,
        plugins: '',
        players: playerConfig,
        sources: [
          {
            bitrate: 600,
            file: LOW.authenticatedStoreUrl
          },
          {
            bitrate: 1200,
            file: HIGH.authenticatedStoreUrl,
            default: true // In quotes because 'default' is a JS reserved word
          }

        ],
        tracks: closedCaptioning,
        playbackRateControls: true
      });

    player.onReady(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const startTime = this.props.videoStartTime || Number(urlParams.get('startTime'));

      if (startTime && !isNaN(startTime) && startTime > 0) {
        player.seek(startTime);
      }
    });

    if (this.state.withAnalytics) {
      this.setupAnalyticsEvents();
    }
  };

  setupAnalyticsEvents = () => {
    jwplayer().on('play', () => {
      // keep track of playStarted so usage event does not fire twice
      // do not fire usage event until state has updated
      if (this.props.getVideoLength) this.props.getVideoLength(this.getVideoDuration());

      if (!this.state.playStarted) {
        this.setState({playStarted: true}, () => {
          videoPlayed(this.props.eid, this.state.searchIndex);
          this.trackAdobeEvent(EVENTS.VIDEO_START);
        });
      } else {
        this.trackAdobeEvent(EVENTS.VIDEO_PLAY);
      }
    });

    jwplayer().on('pause', () => {
      // Chrome will automatically stop the video from autoplaying.
      // In this case, prevent the analytics event from firing
      if (!this.state.playStarted) {
        return;
      }

      this.trackAdobeEvent(EVENTS.VIDEO_STOP);
    });

    jwplayer().on('complete', () => {
      this.trackAdobeEvent(EVENTS.VIDEO_COMPLETE);
    });
  };

  trackAdobeEvent = (eventName) => {
    const length = this.getVideoDuration();
    const position = this.getVideoPosition();
    trackEvent(eventName, {
      video: {
        id: this.props.eid,
        length,
        position
      }
    });
  };

  render () {
    if (this.props.callback) {
      this.state.videoLoaded && this.props.callback(this.playerRef.current.clientHeight);
    }
    return (
      <div ref={this.playerRef}>
        <div className='' id={containerId}>&nbsp;</div>
      </div>
    );
  }
}

VideoPlayer.displayName = 'VideoPlayer';
