import { defineMessages } from 'react-intl';
import { SOURCE_TYPES } from '../constants';

const messages = defineMessages({
  thumbnailOfAnItemTitled: {
    id: 'AltTextCoverImg.thumbnailOfAnItemTitled',
    defaultMessage: 'Thumbnail of a {contentType} titled'
  },
  thumbnail: {
    id: 'AltTextCoverImg.thumbnailOfAnItem',
    defaultMessage: 'Thumbnail'
  },
  book: {
    id: 'BookTile.book',
    defaultMessage: 'Book'
  },
  journal: {
    id: 'BookTile.journal',
    defaultMessage: 'Journal'
  },
  emc: {
    id: 'BookTile.emc',
    defaultMessage: 'EMC'
  }
});

const getAltText = ({ contentType, title, intl }) => {
  if (!contentType || !title) {
    return intl.formatMessage(messages.thumbnail);
  }
  const type = contentType?.toLowerCase();
  const contentTypeText = intl.formatMessage(messages[type]);
  const isEMC = contentType === SOURCE_TYPES.EMC;
  const thumbnailOfAnItem = intl.formatMessage(messages.thumbnailOfAnItemTitled,
    { contentType: isEMC ? contentTypeText : contentTypeText.toLowerCase() });
  return (`${thumbnailOfAnItem}: ${title}`);
};

export default getAltText;
