import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import MenuListItem from './MenuListItem';

const MenuList = (props) => {
  const [menuItems, setMenuItems] = useState(props.items);
  const [selectedName, setSelectedName] = useState(props.items); // eslint-disable-line no-unused-vars
  useEffect(() => {
    setMenuItems(props.items);
    setSelectedName(props.selectedName);
  }, [props.selectedName, props.items]);

  return (
    <div className='c-ckm-menu-list'>
      {
        menuItems.map((item, index) => {
          return (
            <MenuListItem
              item={item}
              clickHandler={props.clickHandler}
              isSelected={(item.id || item.name) === props.selectedId}
              key={index}
              label={item.name}
            />
          );
        })
      }
    </div>
  );
};

MenuList.displayName = 'MenuList';
export default MenuList;

MenuList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })).isRequired,
  clickHandler: PropTypes.func,
  selectedId: PropTypes.string
};
