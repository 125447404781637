import { configureStore, combineReducers } from '@reduxjs/toolkit';
import collectionsReducer from './slices/collectionsSlice';
import clinicalSkillsReducer from './slices/clinicalSkillsSlice';

const rootReducer = combineReducers({
  collections: collectionsReducer,
  clinicalSkills: clinicalSkillsReducer
});

const store = configureStore({
  reducer: rootReducer
});

export default store;

export const setupStore = preloadedState => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  });
};
