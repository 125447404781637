import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Toast from './Toast';

const ToastsContainer = ({ toasts }) => {
  const memoizedToasts = useMemo(() => {
    return (
      toasts?.map((toast, index) =>
        <Toast
          id={toast.id}
          isVisible
          title={toast.title}
          body={toast.body}
          onClose={() => { toast.onClose && toast.onClose(toast); }}
          link={toast.link}
          icon={toast.icon}
          iconColor={toast.iconColor}
          color={toast.color}
          autoHide={toast.autoHide}
          key={toast.id || index}
          position={toast.position}
          hideAnchor={toast.hideAnchor}
          maxWidth={toast.maxWidth}
        >{toast.children}</Toast>
      )
    );
  }, [toasts]);

  return (
    <div className='c-els-toast-container' aria-live='assertive'>
      {toasts.length > 0 && memoizedToasts}
    </div>
  );
};

ToastsContainer.propTypes = {
  toasts: PropTypes.arrayOf(
    PropTypes.shape({
      isVisible: PropTypes.bool.isRequired,
      title: PropTypes.string,
      body: PropTypes.string,
      onClose: PropTypes.func,
      icon: PropTypes.string,
      iconColor: PropTypes.string,
      color: PropTypes.string,
      autoHide: PropTypes.bool
    })
  )
};

ToastsContainer.displayName = 'ToastsContainer';
export default ToastsContainer;
