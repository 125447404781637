// Utils for handling URL manipulation
const { DEFAULT } = require('../config/productConfig');
const { test, replace } = require('ramda');

const isFullUrl = test(/^http/);

const formatUriContext = (uri, contextPath) => {
  const pathTest = new RegExp(`^${contextPath}`);

  if (isFullUrl(uri) || test(pathTest, uri)) {
    // if using a full URL, assume prefix already exists
    // or if URL already has prefix, do nothing
    return uri;
  } else if (test(DEFAULT.regexp, uri)) {
    // if URL has default prefix but needs another, replace prefix
    return replace(DEFAULT.path, contextPath, uri);
  } else {
    // otherwise, add prefix to URI
    return `${contextPath}${uri}`;
  }
};

const concatQueryObjectToQueryString = (queryObj) => {
  let result = '';
  // eslint-disable-next-line no-unused-vars
  for (const key in queryObj) {
    result += `&${key}=${encodeURIComponent(queryObj[key])}`;
  }
  return result;
};

module.exports = {
  formatUriContext,
  concatQueryObjectToQueryString
};
