import React, {Component, createRef} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, defineMessages} from 'react-intl';
import Modal from './Modal';
import VideoPlayer from './VideoPlayer';
import CardSource from './CardSource';
import MediaPopoutImage from './MediaPopoutImage';
import MediaPopoutModel from './MediaPopoutModel';
import MediaViewNavigation from '../../components/common/MediaViewNavigation';
import MediaPopoutPresentationPanel from './MediaPopoutPresentationPanel';
import SplitPane, { Pane } from 'react-split-pane';
import InternalLink from '../common/InternalLink';
import PresentationButton from './toolbar/PresentationButton';
import PresentationModal from './toolbar/PresentationModal';
import ModalButtons from '../../components/common/ModalButtons';
import SaveToCollectionButton from './SaveToCollectionButton';
import InfoMessage from './InfoMessage';
import FeatureOn from '../../components/feature-flag/FeatureOn';
import {CONTENT_TYPE_NAMES, IMAGE_VIEWS, LOCAL_STORAGE } from '../../constants';
import {imageViewed} from '../../services/usageReporting';
import {trackEvent} from '../../utils/eventTrackUtils';
import {returnDefaultCollectionId} from '../../utils/collectionUtils';
import { MainContext } from '../../context/main-context';
import HTMLString from './HTMLString';
import CKApi from '../../services/api-helper';
import { Button } from '@els/els-react--button';
import { Icon } from '@els/els-react--icon';
import { Flyout } from '@els/els-react--flyout';
import { Checkbox } from '@els/els-react--checkbox';
import { Dropdown } from '@els/els-react--dropdown';
import { Modal as LeydenModal } from '@els/els-react--modal';
import { isMobile, isTablet } from '../../utils/elementUtils';
import ParamLink from './ParamLink';
import { getObjectFromQueryString, stringify } from '../../utils/queryStringUtils';
import { useLocalStorage } from '../../hooks';

import '../../assets/img/icon_zoom_in.svg';
import '../../assets/img/icon_zoom_out.svg';
import '../../assets/img/labels-on.svg';
import '../../assets/img/labels-off.svg';

const { isEmpty, toLower } = require('ramda');
const { formatUriContext } = require('../../../server/utils/url-utils');

const messages = defineMessages({
  mediaPopoutVideoTitle: {
    id: 'MediaPopout.aria.videoTitle',
    defaultMessage: 'Video information'
  },
  mediaPopoutImageTitle: {
    id: 'MediaPopout.aria.imageTitle',
    defaultMessage: 'Image information'
  },
  mediaPopoutRefreshFlyout: {
    id: 'readerImage.refreshButtonFlyout',
    defaultMessage: 'Refresh 3D model'
  },
  mediaPopoutCompareTitle: {
    id: 'MediaPopout.compareTitle',
    defaultMessage: 'Side by side view'
  },
  mediaPopoutViewInAppNo: {
    id: 'MediaPopout.link.viewInApp_no',
    defaultMessage: 'No, thanks'
  },
  mediaPopoutViewInAppYes: {
    id: 'MediaPopout.link.viewInApp_yes',
    defaultMessage: 'Yes, open now'
  },
  mediaPopoutDontShowAgain: {
    id: 'MediaPopout.checkbox.dontShowAgain',
    defaultMessage: "Don't show this again"
  },
  labelSelectDropdownLabel: {
    id: 'readerImage.labelSelectDropdownLabel',
    defaultMessage: 'Show all labels'
  },
  labelSelectDropdownOption: {
    id: 'MediaPopout.labelSelectDropdownOption',
    defaultMessage: 'Label view'
  },
  labelSelectViewDropdownLabel: {
    id: 'readerImage.labelSelectLablelView',
    defaultMessage: 'Select view'
  }
});

const zoomButtons = (imageZoom, handleZoomIn, handleZoomOut) => {
  return (
    <div className='c-ckm-modal__zoomButtons'>
      <Button
        className='c-ckm-modal__media-link o-els-flex-layout__item'
        onClick={handleZoomOut}
        type={Button.Types.LINK}
        linkOptions={['on-dark']}
        disabled={imageZoom === 1}
      >
        <svg
          className='icon o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--bottom'
        >
          <use xlinkHref='#icon_zoom_out' />
        </svg>
      </Button>
      <Button
        className='c-ckm-modal__media-link o-els-flex-layout__item'
        onClick={handleZoomIn}
        type={Button.Types.LINK}
        linkOptions={['on-dark']}
        disabled={imageZoom >= 4}
      >
        <svg
          className='icon o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--bottom'
        >
          <use xlinkHref='#icon_zoom_in' />
        </svg>
      </Button>
    </div>
  );
};

export default class MediaPopout extends Component {
  static contextType = MainContext;

  defaultLabelView = this.context.intl.formatMessage(messages.labelSelectDropdownLabel);

  state = {
    // fullscreen enabled by default until we implement drawer view
    fullScreen: true,
    // if not video, default is image
    isVideo: this.props.contenttype === CONTENT_TYPE_NAMES.VIDEO,
    isAddingToPresentation: false,
    imageDimensions: {},
    videoDetails: {},
    imageDetails: {},
    isFullscreenPreview: this.props.contenttype !== CONTENT_TYPE_NAMES.VIDEO,
    isShowingDetails: false,
    resultPosition: this.props.position,
    currentPage: null,
    totalPages: null,
    isPanelVisible: true,
    isDisplayingImage: this.props.contenttype === CONTENT_TYPE_NAMES.IMAGE || this.props.contenttype === IMAGE_VIEWS.COMPARE,
    isDisplayingModel: this.props.contenttype === CONTENT_TYPE_NAMES.MODEL || this.props.contenttype === IMAGE_VIEWS.COMPARE,
    imageZoom: 1,
    modelLabelsVisible: true,
    modelDeepLink: '',
    isOpeningModelInApp: false,
    inAppModalDontShowAgain: 'false',
    currentView: this.props.contenttype,
    currentScreenId: this.props.screenId,
    screenData: null,
    modelLoaderShown: this.props.modelLoaderShown,
    showModelError: false,
    currentLabelView: { view: this.defaultLabelView, value: null },
    updateCollections: 0
  };

  static defaultProps = {
    videoDetailsNeeded: false,
    viewInSource: true,
    contenttype: CONTENT_TYPE_NAMES.IMAGE
  };

  static propTypes = {
    contenttype: PropTypes.string,
    authorList: PropTypes.array,
    eid: PropTypes.string,
    screenIds: PropTypes.array,
    screenId: PropTypes.string,
    itemtitle: PropTypes.string,
    hubeid: PropTypes.string,
    handlePopoutClose: PropTypes.func,
    sourcetitle: PropTypes.string,
    summary_s: PropTypes.string,
    videoDetailsNeeded: PropTypes.bool,
    viewInSource: PropTypes.bool,
    totalItems: PropTypes.number,
    showModelRef: PropTypes.func,
    hideModelRef: PropTypes.func,
    activeScreen: PropTypes.object
  };

  imageRef = createRef();

  toggleUpdateCollections = () => {
    this.setState({updateCollections: this.state.updateCollections + 1});
  };

  updateDefaultCollectionId = (newCollectionId) => {
    this.setState({defaultCollectionId: newCollectionId});
  };

  getCollectionsData = () => {
    CKApi.get('/student/api/collections')
      .then(({ data }) => {
        if (data.results.totalCount) {
          this.setState({collections: data.results.collections});
          this.setState({defaultCollectionId: returnDefaultCollectionId(data.results.collections, this.context.user)});
        }
      })
      .catch((e) => { });
  };

  componentDidMount () {
    if (this.props.contenttype === 'VIDEO') {
      trackEvent('contentView', {
        content: [{
          format: 'MIME-MP4',
          id: this.props.eid,
          title: this.props.itemtitle,
          type: this.props.contenttype
        }]
      });
    } else if (this.props.contenttype === 'IMAGE') {
      CKApi.get(`/student/api/citations/${this.props.eid}`)
        .then(({ data }) => {
          data.citations && this.setState({ imageDetails: data.citations[0] });
        })
        .catch(this.setState({ imageDetails: {} }));

      this.getCollectionsData();

      trackEvent('contentView', {
        content: [{
          authorNames: this.props.authorlist ? this.props.authorlist.join('; ') : null,
          format: 'MIME-JPG',
          id: this.props.eid,
          title: this.props.itemtitle,
          type: this.props.contenttype
        }]
      });
    }
    this.setState({
      totalPages: this.totalPages(20, this.props.searchResponse ? this.props.searchResponse.numberfound : this.props.totalItems)
    });

    if (this.props.screenIds) {
      // TODO - switch to useScreenData if we can update this component to be a functional one
      CKApi.get('/student/api/completeAnatomy/screenData/', {
        params: { eid: this.props.eid},
        paramsSerializer: stringify
      })
        .then(({data}) => {
          this.setState({
            screenData: data,
            modelLabelsVisible: this.returnDefaultLabelsVisibility()
          });
        })
        .catch(() => {
          this.setState({
            modelLoaderShown: false,
            showModelError: true
          });
          console.error('Unable to get 3d model using API');
        });
    }

    this.props.eid && console.log('Image eid: ', this.props.eid);
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.modelLoaderShown !== prevProps.modelLoaderShown) {
      this.setState({modelLoaderShown: this.props.modelLoaderShown});
    }

    if (this.state.updateCollections !== prevState.updateCollections) {
      this.getCollectionsData();
    }
    if (this.props.activeScreen?.id !== prevProps.activeScreen?.id) {
      this.setState({modelLabelsVisible: this.returnDefaultLabelsVisibility()});
    }
  }

  changeViewSize = (fullScreen = true) => (evt) => {
    if (this.state.fullScreen) {
      this.props.handlePopoutClose();
      return;
    }
    this.setState({fullScreen});
  };

  handleImageLoad = (evt) => {
    const searchIndex = this.context.activeIndex;
    imageViewed(this.props.eid, searchIndex);
    this.setPageValue();
  };

  handleZoomIn = (evt) => {
    this.setState({
      imageZoom: this.state.imageZoom + 1
    });
  };

  handleZoomOut = (evt) => {
    this.setState({
      imageZoom: this.state.imageZoom - 1
    });
  };

  getVideoDetails = (videoDetails) => {
    this.setState({ videoDetails });
  };

  showAddToPresentation = () => {
    this.setState({
      isAddingToPresentation: true,
      isShowingDetails: false
    });
  };

  hideAddToPresentation = () => {
    this.setState({
      isAddingToPresentation: false
    });
  };

  handleViewInApp = () => {
    const [localStorageItem] = useLocalStorage(LOCAL_STORAGE.SHOW_MODALS); // eslint-disable-line react-hooks/rules-of-hooks
    if (localStorageItem && localStorageItem.ca_viewInApp === 'true') {
      this.openModelInApp();
    } else {
      this.setState({
        isOpeningModelInApp: true
      });
    }

    trackEvent('buttonClick', {buttonType: 'model-preview:view-in-app'}
    );
  };

  hideOpenModelInApp = () => {
    this.setState({
      isOpeningModelInApp: false
    }, () => {
      document.activeElement.blur();
    });
  };

  handleOpenModelInAppButton = () => {
    const [localStorageItem, setLocalStorageItem] = useLocalStorage(LOCAL_STORAGE.SHOW_MODALS); // eslint-disable-line react-hooks/rules-of-hooks
    setLocalStorageItem(localStorageItem ? {...localStorageItem, ca_viewInApp: this.state.inAppModalDontShowAgain } : { ca_viewInApp: this.state.inAppModalDontShowAgain });
    this.openModelInApp();
    this.hideOpenModelInApp();
  };

  openModelInApp = () => {
    this.context.doRedirect('completeanatomy://' + this.state.modelDeepLink, null, true);
  };

  handleInAppCheckboxSelect = (event) => {
    const {checked} = event.target;
    this.setState({
      inAppModalDontShowAgain: checked.toString()
    });
  };

  toggleDetails = (evt) => {
    this.setState({
      isShowingDetails: !this.state.isShowingDetails,
      isAddingToPresentation: false
    });
  };

  totalPages = (pageSize, totalResults) => {
    return Math.ceil(totalResults / pageSize);
  };

  setPageValue = (resultPosition) => {
    const { location } = this.props;
    if (location) {
      const objSearchQuery = getObjectFromQueryString(location.search);
      const page = objSearchQuery.page || 1;
      this.setState({
        currentPage: parseInt(page)
      });
    }
  };

  updatePosition = (position) => {
    const { results } = this.props;
    const maxPosition = results.length - 1;
    const nextResultPosition = position > maxPosition ? 0 : position < 0 ? maxPosition : position;
    this.setState({
      resultPosition: nextResultPosition
    });
  };

  nextImage = (evt) => {
    this.updatePosition(this.state.resultPosition + 1);
  };

  previousImage = (evt) => {
    this.updatePosition(this.state.resultPosition - 1);
  };

  setCurrentView = (viewType, screenId) => {
    if (viewType !== this.state.currentView) {
      this.props.hideModelRef();
    }
    this.setState({
      currentView: viewType,
      currentScreenId: screenId,
      isDisplayingImage: viewType === CONTENT_TYPE_NAMES.IMAGE || viewType === IMAGE_VIEWS.COMPARE,
      isDisplayingModel: viewType === CONTENT_TYPE_NAMES.MODEL || viewType === IMAGE_VIEWS.COMPARE,
      modelLabelsVisible: this.returnDefaultLabelsVisibility()
    });
  };

  onModelError = () => {
    this.setState({showModelError: true});
    this.setState({modelLoaderShown: false});
    console.error('Unsuccessful attempt to activate model');
  };

  showModel = (parentId, screenId, deepLink) => {
    // Update the view in app button link
    this.setState({
      modelDeepLink: deepLink
    });
    this.props.showModelRef(parentId, screenId, undefined, undefined, this.onModelError);
  };

  getViews = () => {
    const views = [IMAGE_VIEWS.IMAGE, IMAGE_VIEWS.MODEL];
    if (!(isMobile() || isTablet())) {
      // Only show Compare option when user isn't on mobile or tablet
      views.push(IMAGE_VIEWS.COMPARE);
    }

    return views;
  };

  returnDefaultLabelsVisibility = () => {
    return !this.props.returnScreenLabelViews();
  };

  getModelLabelsDropdown = () => {
    const screenLabelViews = this.props.returnScreenLabelViews();
    if (screenLabelViews?.length) {
      screenLabelViews.splice(-1);
    }

    if (screenLabelViews) {
      return (
        <span className='o-els-flex-layout__item'>
          <Dropdown
            id='label-view-dropdown'
            options={screenLabelViews.map((item, index) => {
              const indexNumber = Number(index) + 1;
              return (
                {
                  label: this.context.intl.formatMessage(messages.labelSelectDropdownOption) + ' ' + indexNumber,
                  id: this.state.currentLabelView.value === item && 'activeLabel',
                  onClick: (evt) => { this.selectLabelView(evt, item); }
                }
              );
            })}
            label={this.context.intl.formatMessage(messages.labelSelectViewDropdownLabel)}
            className='select__button'
            placement='bottom-end'
            theme='minimal'
          />
        </span>
      );
    }
  };

  selectLabelView = (evt, view) => {
    if (view === null) {
      this.props.showModelRef(`canvas-parent-fullscreen-${this.props.eid}`, undefined, undefined, undefined, undefined, true);
    } else {
      this.props.updateLabelViewRef(view);
    }

    this.setState({ currentLabelView: { view: evt.label, value: view }, modelLabelsVisible: true });
  };

  getModelHeaderOptions = () =>
    <>
      <Flyout
        className='o-els-flex-layout__item'
        placement='bottom'
        trigger='hover'
        theme='simple'
        modifiers={{flip: {enabled: false}, preventOverflow: {enabled: true}}}
        flyout={
          <FormattedMessage
            id='readerImage.labelsButtonFlyout'
            defaultMessage='Turn labels {onOrOff}'
            values={{ onOrOff: this.state.modelLabelsVisible ? 'off' : 'on' }}
          />
        }
        shouldCloseOnESC
        id='labels-btn-flyout'
      >
        <Button
          onClick={() => {
            this.props.toggleModelLabels(!this.state.modelLabelsVisible);
            this.setState({modelLabelsVisible: !this.state.modelLabelsVisible});
            this.state.modelLabelsVisible ? this.setState({currentLabelView: { view: this.defaultLabelView, value: null }}) : this.setState({currentLabelView: { view: this.context.intl.formatMessage(messages.labelSelectDropdownOption) + ' ' + 1, value: this.props.returnScreenLabelViews()[0] }});
          }}
          type={Button.Types.LINK}
          className=''
          id='labels-btn'
        >
          <svg
            className='icon o-els-icon-svg o-els-icon-svg--1x o-els-icon-svg--bottom'
          >
            <use xlinkHref={`#labels-${this.state.modelLabelsVisible ? 'on' : 'off'}`} />
          </svg>
        </Button>
      </Flyout>
      <Flyout
        className='o-els-flex-layout__item'
        placement='bottom'
        trigger='hover'
        theme='simple'
        modifiers={{flip: {enabled: false}, preventOverflow: {enabled: false}}}
        flyout={this.context.intl.formatMessage(messages.mediaPopoutRefreshFlyout)}
        shouldCloseOnESC
        id='refresh-btn-flyout'
      >
        <Button
          onClick={() => {
            this.setState({modelLabelsVisible: !this.props.returnScreenLabelViews()});
            this.props.showModelRef(`canvas-parent-fullscreen-${this.props.eid}`);
}}
          type={Button.Types.LINK}
          className=''
          id='refresh-btn'
        >
          <Icon
            a11y={{ name: this.context.intl.formatMessage(messages.mediaPopoutRefreshFlyout) }}
            sprite='Redo'
            className=''
            textAlignment='bottom'
            size={Icon.Sizes.XS}
            isVisible
            isTextFirst
            id='refresh-btn-icon'
          />
        </Button>
      </Flyout>
    </>;

  // TODO truncate author list to 2 max
  render () {
    const { videoDetails, resultPosition, imageDimensions, isShowingDetails, isAddingToPresentation, fullScreen, isPanelVisible, isFullscreenPreview, isDisplayingImage, isDisplayingModel, imageZoom, isVideo, currentPage, totalPages, imageDetails, currentView, currentScreenId, isOpeningModelInApp, screenData} = this.state;
    const { results, location, screenIds } = this.props;
    const dataSource = !isEmpty(videoDetails) ? videoDetails : results ? results[resultPosition] : this.props;
    const { eid, hubeid, copyrightyear, pagerange, authorlist, itemtitle, sourcetitle, pubdate, volumeissue } = dataSource;
    const imageSource = formatUriContext(`/api/content/imageByEntitlement/${eid}`, this.context.productConfig.path);
    const summary = this.props.summary || this.props.summary_s || videoDetails.summary;
    const pathName = location ? location.pathname : '';
    const cardSourceProps = {authorlist, pubdate, volumeissue, pagerange, copyrightyear};
    const parentContentType = /^3-s2\.0/.test(eid) ? 'book' : 'emc';
    const ariaTitleText = isVideo
      ? this.context.intl.formatMessage(messages.mediaPopoutVideoTitle)
      : this.context.intl.formatMessage(messages.mediaPopoutImageTitle);
    const imageList = results || [];
    const leftNavigationClass = (currentPage === 1 && resultPosition === 0) ? 'u-ckm-invisible' : '';
    const rightNavigationClass = (currentPage === totalPages && resultPosition === (imageList.length - 1)) ? 'u-ckm-invisible' : '';
    const panelSize = (isDisplayingImage && isDisplayingModel) ? '50%' : '100%';
    const showFooter = !!screenIds && this.context.user.hasCompleteAnatomyAccess();

    return (
      <>
        <Modal
          size={isFullscreenPreview ? 'full' : 'large'}
          isVideo={isVideo}
          handleModalClose={this.props.handlePopoutClose}
          ariaTitleText={ariaTitleText}
          focusTrapPaused
          underlayClickExits={!isFullscreenPreview}
        >
          <div className={`c-ckm-modal__media c-ckm-modal__media--${toLower(currentView)}`} id='media-modal'>
            {(fullScreen && isVideo) &&
              <button
                onClick={this.changeViewSize(false)}
                className='c-ckm-modal__close c-ckm-icon--lg c-ckm-icon--cross-lg u-ckm-cursor-pointer'
                aria-label='Close modal'
              />}
            <div className='c-ckm-modal__media-container'>
              {this.state.isDisplayingModel && isMobile() && <InfoMessage />}
              {isFullscreenPreview &&
                <div
                  className='c-ckm-modal__media-overlay o-els-flex-layout'
                />}
              <div className='c-ckm-modal__wrapper'>
                <div
                  className='c-ckm-modal__media-header o-els-flex-layout c-ckm-modal__media-header--is-open'
                >
                  {this.state.isDisplayingModel && currentView !== IMAGE_VIEWS.COMPARE && this.getModelHeaderOptions()}
                  <div className='c-ckm-modal__media-header--title o-els-flex-layout__item o-els-flex-layout__item--grow'>
                    {currentView === IMAGE_VIEWS.COMPARE
                        ? this.context.intl.formatMessage(messages.mediaPopoutCompareTitle)
                        : <HTMLString content={this.state.isDisplayingModel ? this.props.activeScreen?.name : itemtitle} />}
                  </div>

                  {currentView === IMAGE_VIEWS.IMAGE && zoomButtons(imageZoom, this.handleZoomIn, this.handleZoomOut)}

                  {currentView === IMAGE_VIEWS.IMAGE && this.state.defaultCollectionId &&
                    <FeatureOn featureName='collections'>
                      <span className='c-ckm-modal__media-link o-els-flex-layout__item'>
                        <SaveToCollectionButton
                          eid={eid}
                          defaultCollectionId={this.state.defaultCollectionId} collections={this.state.collections} type={this.props.contenttype.includes('BOOK') ? 'chapter' : 'multimedia'} placement='top' updateCollections={this.toggleUpdateCollections} updateDefaultCollectionId={this.updateDefaultCollectionId} sourcetitle={this.props.sourcetitle}
                        />
                      </span>
                    </FeatureOn>}

                  {this.props.viewInSource && currentView === IMAGE_VIEWS.IMAGE &&
                    <Button
                      className='c-ckm-modal__media-link o-els-flex-layout__item'
                      onClick={() => { this.context.doRedirect(`/content/${parentContentType}/${hubeid}#${eid}`); }}
                      type={Button.Types.LINK}
                      linkOptions={['on-dark']}
                    >
                      <Icon
                        a11y={{ name: 'View in source' }}
                        sprite={Icon.Sprites.OPEN_BOOK}
                        className='u-els-margin-right-1o2'
                        id='media_modal_viewInPresentation'
                        size={Icon.Sizes.XS}
                        textAlignment='bottom'
                        isVisible
                        isTextFirst
                      />
                      <FormattedMessage
                        id='MediaPopout.link.source'
                        defaultMessage='View in source'
                      />
                    </Button>}
                  {this.context.user.hasPresentationAccess() && currentView === IMAGE_VIEWS.IMAGE &&
                    <span className='c-ckm-modal__media-link o-els-flex-layout__item'>
                      <PresentationButton
                        clickHandler={this.showAddToPresentation}
                        linkOptions={isFullscreenPreview ? ['on-dark'] : []}
                      />
                    </span>}
                  {!fullScreen &&
                    <button className='c-ckm-link o-els-flex-layout__item' onClick={this.changeViewSize(true)}>
                      <FormattedMessage
                        id='MediaPopout.display.fullSize'
                        defaultMessage='View full size'
                      />
                    </button>}
                  {currentView === IMAGE_VIEWS.IMAGE &&
                    <Button
                      className='c-ckm-modal__media-link c-ckm-modal__media-link--details o-els-flex-layout__item'
                      onClick={this.toggleDetails}
                      type={Button.Types.LINK}
                      linkOptions={['on-dark']}
                    >
                      <Icon
                        a11y={{ name: 'Image Details' }}
                        sprite={Icon.Sprites.INFORMATION_OUTLINE_CIRCLE}
                        className='u-els-margin-right-1o2'
                        id='media_modal_imageDetails'
                        size={Icon.Sizes.XS}
                        textAlignment='bottom'
                        isVisible
                        isTextFirst
                      />
                      <FormattedMessage
                        id='MediaPopout.link.imageDetails'
                        defaultMessage='Image Details'
                      />
                    </Button>}

                  {currentView === IMAGE_VIEWS.MODEL && !isMobile() &&
                    <>
                      <div className='u-els-margin-right'>
                        {this.getModelLabelsDropdown()}
                      </div>
                      <Button
                        className='c-ckm-modal__media-link o-els-flex-layout__item'
                        onClick={this.handleViewInApp}
                        type={Button.Types.PRIMARY}
                        id='viewInAppBtnId'
                      >
                        <FormattedMessage
                          id='MediaPopout.link.viewInApp'
                          defaultMessage='View in app'
                        />
                        <Icon
                          a11y={{ name: 'View in app' }}
                          sprite={Icon.Sprites.UP_RIGHT}
                          className='u-els-margin-left-1o2'
                          id='media_modal_viewInApp'
                          size={Icon.Sizes.XS}
                          textAlignment='middle'
                          isVisible
                          isTextFirst
                        />
                      </Button></>}
                  <div className='o-els-flex-layout__item c-ckm-modal__media-link--close'>
                    <Button onClick={this.changeViewSize(false)} type={Button.Types.LINK} className='' id='close-fullscreen-btn'>
                      <Icon a11y={{ name: 'Close modal' }} sprite={Icon.Sprites.CLOSE} id='' size={Icon.Sizes.XS} isVisible />
                    </Button>
                  </div>
                </div>
                <div className={`c-ckm-modal__media-content c-ckm-modal__media-content--${toLower(currentView)}`}>
                  {isVideo
                ? (
                  <VideoPlayer eid={eid} getVideoDetails={this.getVideoDetails} videoDetailsNeeded={this.props.videoDetailsNeeded} intl={this.context.intl}>
                    <FormattedMessage
                      id='MediaPopout.display.video'
                      defaultMessage='Video'
                    />
                  </VideoPlayer>
                ) : (
                  <>

                    <SplitPane split='vertical' defaultSize={panelSize} minSize={400}>
                      {isDisplayingImage &&
                        <Pane className=''>
                          {currentView === IMAGE_VIEWS.COMPARE && zoomButtons(imageZoom, this.handleZoomIn, this.handleZoomOut)}

                          <div className={`c-ckm-modal__media-navigation c-ckm-modal__media-navigation--left ${leftNavigationClass}`}>
                            {results &&
                              <ParamLink
                                basePath={pathName}
                                params={{ page: resultPosition === 0 ? (currentPage - 1) : currentPage}}
                              >
                                <Button
                                  className=''
                                  onClick={this.previousImage}
                                  type={Button.Types.LINK}
                                  linkOptions={['on-dark', 'icon']}
                                >
                                  <Icon
                                    a11y={{ name: 'Previous' }}
                                    sprite={Icon.Sprites.CHEVRON_LEFT}
                                    id='media_modal_previous'
                                    size={Icon.Sizes.ML}
                                    textAlignment='bottom'
                                    isVisible
                                    isTextFirst
                                  />
                                </Button>
                              </ParamLink>}
                          </div>

                          <MediaPopoutImage
                            source={imageSource}
                            summary={imageDetails.summary_s}
                            imageDimensions={imageDimensions}
                            imageZoom={imageZoom}
                            handleImageLoad={this.handleImageLoad}
                            ref={this.imageRef}
                          />

                          <div className={`c-ckm-modal__media-navigation c-ckm-modal__media-navigation--right ${rightNavigationClass}`}>
                            {results &&
                              <ParamLink basePath={pathName} params={{ page: resultPosition === (results.length - 1) ? (currentPage + 1) : currentPage}}>
                                <Button
                                  className=''
                                  onClick={this.nextImage}
                                  type={Button.Types.LINK}
                                  linkOptions={['on-dark', 'icon']}
                                >
                                  <Icon
                                    a11y={{ name: 'Next' }}
                                    sprite={Icon.Sprites.CHEVRON_RIGHT}
                                    id='media_modal_next'
                                    size={Icon.Sizes.ML}
                                    textAlignment='bottom'
                                    isVisible
                                    isTextFirst
                                  />
                                </Button>
                              </ParamLink>}
                          </div>
                        </Pane>}

                      {isDisplayingModel &&
                        <Pane className=''>
                          {this.state.currentView === IMAGE_VIEWS.COMPARE &&
                            <div className='c-ckm-modal__model-icons o-els-flex-layout'>
                              {this.getModelHeaderOptions()}
                              <div className='c-ckm-modal__model-icons-title o-els-flex-layout__item o-els-flex-layout__item--grow'>
                                <HTMLString content={this.props.activeScreen?.name} />
                              </div>
                              <div className='o-els-flex-layout__item u-els-margin-left'> {this.getModelLabelsDropdown()} </div>
                            </div>}
                          <MediaPopoutModel
                            currentView={currentView}
                            eid={eid}
                            screenIds={screenIds}
                            screenId={currentScreenId?.toString()}
                            screenData={screenData}
                            showModelRef={this.showModel}
                            modelLoaderShown={this.state.modelLoaderShown}
                            activeScreen={this.props.activeScreen}
                            isFooterVisible={isPanelVisible}
                            handlePopoutClose={this.props.handlePopoutClose}
                            showModelError={this.state.showModelError}
                          />
                        </Pane>}

                    </SplitPane>

                    {isShowingDetails &&
                      <div className='c-ckm-modal__media-info'>
                        <div className='o-els-flex-layout c-ckm-modal__media-info-header'>
                          <div className='o-els-flex-layout__item o-els-flex-layout__item--grow'>
                            <FormattedMessage
                              id='SearchPage.display.fullscreen.image'
                              defaultMessage='IMAGE'
                            />
                          </div>
                          <div className='o-els-flex-layout__item'>
                            <Button onClick={this.toggleDetails} type={Button.Types.LINK} className=''>
                              <Icon a11y={{ name: 'Close modal' }} sprite={Icon.Sprites.CLOSE} id='' size={Icon.Sizes.XS} isVisible />
                            </Button>
                          </div>
                        </div>
                        <h2>
                          <HTMLString content={itemtitle} />
                        </h2>
                        <p className='c-ckm-modal__media-summary'>
                          <strong><FormattedMessage id='SearchPage.display.fullscreen.description' defaultMessage='Description' />:&nbsp;</strong>
                          {summary}
                        </p>
                        <p className='c-ckm-modal__media-source'>
                          <strong><FormattedMessage id='SearchPage.display.fullscreen.source' defaultMessage='Source' />:&nbsp;</strong>
                          {sourcetitle}
                        </p>
                        <CardSource {...cardSourceProps} />
                      </div>}

                  </>
                )}
                </div>
                {showFooter &&
                  <div
                    className='c-ckm-modal__media-footer c-ckm-modal__media-footer--is-open'
                  >
                    <MediaViewNavigation
                      eid={eid}
                      screenIds={screenIds}
                      screenId={currentScreenId?.toString()}
                      screenData={screenData}
                      imageSrc={imageSource}
                      viewChangeCallback={this.setCurrentView}
                      views={this.getViews()}
                      currentView={currentView}
                      showSlider
                      showNavigation
                    />
                  </div>}
              </div>

              {(isAddingToPresentation && isFullscreenPreview) &&
                <MediaPopoutPresentationPanel
                  handleClose={this.hideAddToPresentation}
                  eid={eid}
                  resultPosition={this.state.resultPosition}
                />}

              {!isFullscreenPreview &&
                <div className='c-ckm-modal__media-info'>
                  <div className='c-ckm-modal__media-type'>
                    {isVideo
                  ? (
                    <FormattedMessage
                      id='SearchPage.display.video'
                      defaultMessage='Video'
                    />
                  ) : (
                    <FormattedMessage
                      id='SearchPage.display.image'
                      defaultMessage='Image'
                    />
                  )}
                  </div>
                  {isAddingToPresentation
                ? (
                  <PresentationModal
                    eid={eid}
                    handleClose={this.hideAddToPresentation}
                  />
                ) : (
                  <>
                    <h2>
                      <HTMLString content={itemtitle} />
                    </h2>
                    <p className='c-ckm-modal__media-source'>{sourcetitle}</p>
                    <CardSource {...cardSourceProps} />
                    <p className='c-ckm-modal__media-summary'>{this.state.isVideo ? summary : this.state.imageDetails.summary_s}</p>
                    {!isVideo &&
                      <p className='c-ckm-modal__media-link-container'>
                        {this.props.viewInSource &&
                          <InternalLink
                            className='c-ckm-modal__media-link'
                            href={`/content/${parentContentType}/${hubeid}#${eid}`}
                          >
                            <FormattedMessage
                              id='MediaPopout.link.source'
                              defaultMessage='View in source'
                            />
                          </InternalLink>}
                        {this.context.user.hasPresentationAccess() && !this.context.isEmbedded &&
                          <span className='c-ckm-modal__media-link'>
                            <PresentationButton
                              clickHandler={this.showAddToPresentation}
                            />
                          </span>}
                        {!fullScreen &&
                          <button className='c-ckm-link' onClick={this.changeViewSize(true)}>
                            <FormattedMessage
                              id='MediaPopout.display.fullSize'
                              defaultMessage='View full size'
                            />
                          </button>}
                      </p>}
                  </>
                )}
                </div>}
            </div>
          </div>
        </Modal>

        {isOpeningModelInApp &&
          <LeydenModal className='' type='neutral' rootId='media-modal' onClose={this.hideOpenModelInApp} shouldPreventClickOverlayToClose>
            <div className='c-ckm-modal__container'>
              <h2>View in app</h2>
              <p className='u-els-margin-top-3o4'>
                <FormattedMessage
                  id='MediaPopout.info.viewInApp_confirm'
                  defaultMessage='Are you sure you want to open Complete Anatomy app?'
                />
              </p>
              <Checkbox
                label={this.context.intl.formatMessage(messages.mediaPopoutDontShowAgain)}
                onChange={this.handleInAppCheckboxSelect}
                className='u-els-margin-top'
              />
              <ModalButtons
                primaryButton={{ label: this.context.intl.formatMessage(messages.mediaPopoutViewInAppYes), onClick: this.handleOpenModelInAppButton, id: 'btn-openInApp' }}
                secondaryButton={{ label: this.context.intl.formatMessage(messages.mediaPopoutViewInAppNo), onClick: this.hideOpenModelInApp }}
              />
            </div>
          </LeydenModal>}

      </>
    );
  }
}

MediaPopout.displayName = 'MediaPopout';
