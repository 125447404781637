import React from 'react';
import { replace } from 'ramda';
import { defineMessages } from 'react-intl';
import { Checkbox } from '@els/els-react--checkbox';
import { removeWhitespace } from '../../utils/stringUtils';

const contentTypeNames = defineMessages({
  Books: {
    id: 'Filters.contentType.books',
    defaultMessage: 'Books'
  },
  Images: {
    id: 'Filters.contentType.images',
    defaultMessage: 'Images'
  },
  Videos: {
    id: 'Filters.contentType.videos',
    defaultMessage: 'Videos'
  },
  EMC: {
    id: 'Filters.contentType.emc',
    defaultMessage: 'EMC'
  }
});

const accessibilityMessages = defineMessages({
  LabelsAccessibilityName: {
    id: 'Filters.labels.accessibilityName',
    defaultMessage: 'Filter: {filterName}. Items amount: {facetCount}'
  }
});

const addZeroWidthSpaceAfterSlash = replace(/\//g, '/\u200B');

const translatedFacetName = (sectionName, name, intl) => {
  // If facet section is not Content Type, do not perform translations
  if (removeWhitespace(sectionName) !== 'ContentType') {
    return addZeroWidthSpaceAfterSlash(name);
  }

  const contentTypeMessage = contentTypeNames[removeWhitespace(name)];
  if (!contentTypeMessage) {
    return name;
  }

  return intl.formatMessage(contentTypeMessage);
};

const FilterItem = (props) => {
  const itemName = translatedFacetName(
    props.sectionName,
    props.name,
    props.intl
  );
  const handleSelection = (handlerFn, facetquery, sectionName, itemName) => {
    props.change(itemName);
    handlerFn(facetquery, sectionName);
  };
  return (
    <li
      className='c-ckm-filter__list-item c-ckm-filter__list-item__checkbox'
      ref={props.innerRef}
    >
      <Checkbox
        label={itemName}
        onChange={() =>
          handleSelection(
            props.facetSelected,
            props.facetquery,
            props.sectionName,
            itemName
          )}
        checked={props.checked}
        id={`facet-${props.facetquery}`}
        aria-describedby={`facet-${props.facetquery}`}
        disabled={props.isLoadingContent}
      />
      <button
        onClick={() =>
          handleSelection(
            props.facetSelected,
            props.facetquery,
            props.sectionName,
            itemName
          )}
        id={`facet-${props.facetquery}-count`}
        aria-label={props.intl.formatMessage(accessibilityMessages.LabelsAccessibilityName, {filterName: props.name, facetCount: props.count})}
        className='c-ckm-filter__list-label-count'
        tabIndex={-1}
        disabled={props.isLoadingContent}
      >
        {props.count}
      </button>
    </li>
  );
};

FilterItem.displayName = 'FilterItem';

export default FilterItem;
