import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BrokenImage from '../../assets/img/ico_broken-img.png';
import {defineMessages} from 'react-intl';

const messages = defineMessages({
  ImageModalPrompt: {
    id: 'Image.message.ModalPrompt',
    defaultMessage: 'Open modal:'
  }
});

class Image extends Component {
  state = {
    imageLoaded: false,
    imageLoadFailed: false
  };

  static propTypes = {
    className: PropTypes.string.isRequired,
    emptyClassName: PropTypes.string,
    errorClassName: PropTypes.string,
    src: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    reference: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ]),
    altReference: PropTypes.string,
    isModalTrigger: PropTypes.bool
  };

  static defaultProps = {
    isModalTrigger: true
  };

  handleImageLoad = () => this.setState({imageLoaded: true});

  handleImageLoadError = () => this.setState({imageLoaded: false, imageLoadFailed: true});

  getImageClassName = ({className, emptyClassName = className, errorClassName = className}) => {
    if (this.state.imageLoadFailed) {
      return errorClassName;
    }
    return this.state.imageLoaded ? className : emptyClassName;
  };

  getImageElement = (props) => {
    const imageClass = this.getImageClassName(props);
    const imgSrc = this.state.imageLoadFailed ? BrokenImage : props.src;

    const altPrefix = props.isModalTrigger ? `${props.intl.formatMessage(messages.ImageModalPrompt)} ` : '';
    return props.src
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      ? (
        <img
          className={imageClass}
          src={imgSrc}
          onLoad={this.handleImageLoad}
          onError={this.handleImageLoadError}
          alt={`${altPrefix}${props.reference ? props.reference : props.altReference}`}
        />
      ) : (
        <img
          className={imageClass}
          alt=''
        />
      );
  };

  render () {
    return this.getImageElement(this.props);
  }
}

export default Image;

Image.displayName = 'Image';
