import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { values, flatten, compose, isEmpty, omit, map, toPairs, whereEq, findIndex } from 'ramda';
import { toggleFacet, getActiveFacets } from '../../../src/utils/facetUtils';
import FilterSection from './FilterSection';
import { defineMessages } from 'react-intl';
import { isMobile, isTablet, isDesktop } from '../../utils/elementUtils';
import ResizeDetector from 'react-resize-detector';
import AtoZ from '../../features/browse/AtoZ';
import { MainContext } from '../../context/main-context';
import { moveInArray } from '../../utils/functionUtils';
import ResetButton from '../common/ResetButton';
import { ELSSlideSwitch } from '@els/els-component-form-field-react';

const mapToArray = compose(
  map(([key, value]) => ({ name: key, facets: value })),
  toPairs
);

const messages = defineMessages({
  savedContent: {
    id: 'Filters.item.savedContent',
    defaultMessage: 'Results from my saved books'
  },
  filterDescr: {
    id: 'Filter.heading.filterResults',
    defaultMessage: 'Filter results: '
  },
  filterDescrMobile: {
    id: 'Filter.heading.filterResultsMobile',
    defaultMessage: 'Filter results'
  }
});

export default class Filters extends Component {
  state = {
    isOpen: false,
    isMobile: false,
    checked: {},
    facetsInitialState: {},
    savedBooksCheck: this.props.savedContentSelected,
    filtredFacets: this.props.facets
  };

  static propTypes = {
    facets: PropTypes.object.isRequired,
    handleFacetSelection: PropTypes.func.isRequired,
    handleLetterClear: PropTypes.func,
    handleLetterSelection: PropTypes.func,
    intl: PropTypes.object.isRequired,
    letters: PropTypes.object,
    showAtoZ: PropTypes.bool,
    selectedFiltersAnnouncement: PropTypes.string,
    isBrowseResults: PropTypes.bool,
    isSearchingWithin: PropTypes.bool,
    isSummaries: PropTypes.bool
  };

  static defaultProps = {
    facets: {}
  };

  static contextType = MainContext;

  filterFacets = () => {
    const hideContentLevelFilter = this.context?.productConfig?.context === 'nursing' || false;
    return hideContentLevelFilter ? omit(['Content Level'], this.props.facets) : this.props.facets;
  };

  componentDidMount () {
    const filtersArray = mapToArray(this.props.facets);
    const facetsInitialState = filtersArray.map((filter) => filter.facets.map(item => item).reduce((target, key) => {
      target[key.name.split('/')[0]] = false; // use first facet before slash to avoid bug with checked state
      return target;
    }, {}));
    this.setState({
      checked: { ...facetsInitialState[0], ...facetsInitialState[1] },
      facetsInitialState: { ...facetsInitialState[0], ...facetsInitialState[1] },
      filtredFacets: this.filterFacets()
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.facets !== this.props.facets) {
      const filtersArray = mapToArray(this.props.facets);
      const facetsInitialState = filtersArray.map((filter) => filter.facets.map(item => item));
      this.setState({
        checked: { ...facetsInitialState[0], ...facetsInitialState[1] },
        facetsInitialState: { ...facetsInitialState[0], ...facetsInitialState[1] },
        filtredFacets: this.filterFacets()
      });
    }
    if (prevProps.updateFilters !== this.props.updateFilters) {
      this.setState({
        checked: this.state.facetsInitialState,
        savedBooksCheck: false,
        filtredFacets: this.filterFacets()
      });
    }
  }

  toggleFilters = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleToggleFiltersKeyPress = (evt) => {
    if (evt.keyCode === 32 || evt.keyCode === 13) {
      this.toggleFilters();
    }
  };

  handleFacetSelection = (facetValue, sectionKey) => {
    const activeFacets = toggleFacet(facetValue, sectionKey, getActiveFacets(this.props.facets));
    const facetQueriesAsArray = compose(flatten, values)(activeFacets);
    this.props.handleFacetSelection(facetQueriesAsArray, false, activeFacets);
  };

  onResize = () => {
    this.setState({ isMobile: isMobile() });
  };

  updateCheckStatus = (name) => {
    this.setState(prevState => ({
      checked: {
        ...prevState.checked,
        [name.split('/')[0]]: !this.state.checked[name.split('/')[0]] // use first facet before slash to avoid bug with checked state
      }
    }));
  };

  getFilters = (width) => {
    const { user } = this.context;
    const facets = this.state.filtredFacets;
    const filterClass = this.state.isOpen ? 'u-ckm-visible' : 'u-ckm-invisible';
    let lastSectionNumber = Object.keys(facets).length - 1;
    let filtersArray = mapToArray(facets);

    const subContentTypeIndex = findIndex(whereEq({ name: 'Sub Content Type' }))(filtersArray);
    if (subContentTypeIndex !== -1) {
      // reorder array if ancillary content is present in browse or search results
      const n = this.props.isBrowseResults ? 0 : 1;
      filtersArray = moveInArray(subContentTypeIndex, n, filtersArray);
    } else {
      // ensure Content Level is the first filter listed
      const contentLevelIndex = findIndex(whereEq({ name: 'Content Level' }))(filtersArray);
      if (contentLevelIndex > -1) filtersArray = moveInArray(contentLevelIndex, 0, filtersArray);
    }

    const conditionIndex = findIndex(whereEq({ name: 'Condition' }))(filtersArray);
    if (conditionIndex !== -1) {
      // remove condition filter if user is a student
      if (user && !user.hasInstructorRole()) {
        filtersArray = filtersArray.filter(item => item.name !== 'Condition');
      } else {
        // reorder array if condition is present in browse or search results
        filtersArray = moveInArray(conditionIndex, 2, filtersArray);
      }
    }

    if (this.props.isSearchingWithin) {
      filtersArray = filtersArray.filter(item => item.name === 'Content Type');
      lastSectionNumber = 0;
    }

    return filtersArray.map((filter, index) =>
      <FilterSection
        key={index}
        intl={this.props.intl}
        sectionName={filter.name}
        facets={filter.facets}
        facetSelected={this.handleFacetSelection}
        filterClass={filterClass}
        toggleFilters={this.toggleFilters}
        lastSection={index === lastSectionNumber}
        checked={this.state.checked}
        change={this.updateCheckStatus}
        truncatedLength={filter.name === 'Specialties' ? 3 : null}
        isLoadingContent={this.props.isLoadingContent}
      />
    );
  };

  resetActiveCheckboxes = (e) => {
    e.stopPropagation();
    this.props.handleFacetSelection([]);
    this.setState({
      checked: this.state.facetsInitialState,
      savedBooksCheck: false,
      filtredFacets: this.filterFacets()
    });

    this.props.showSearchWithinSaved && !this.props.isSearchingWithin && this.props.handleSavedSelection(false);
  };

  handleSavedBooks = () => {
    this.props.handleSavedSelection(!this.props.savedContentSelected);
    this.setState({ savedBooksCheck: !this.state.savedBooksCheck });
  };

  hasCheckedFacets = () => {
    return Object.values(this.state.filtredFacets).some(section => section.some(facet => facet.isActive));
  };

  render () {
    const mobileProps = this.state.isMobile ? { onClick: this.toggleFilters, onKeyDown: this.handleToggleFiltersKeyPress, 'aria-expanded': this.state.isOpen, tabIndex: '0', role: 'button' } : null;

    return (
      <ResizeDetector
        handleWidth
        onResize={this.onResize}
        refreshMode='debounce'
        refreshOptions={{ trailing: true }}
        render={({ width }) =>
          <div className='c-ckm-filter' role='region' aria-label='Filter results'>
            {(!isEmpty(this.props.facets) || !isEmpty(this.props.letters)) &&
              <div className='c-ckm-filter__heading' {...mobileProps}>
                <h2 className='u-ckm-heading-panel'>
                  {isMobile() ? this.props.intl.formatMessage(messages.filterDescrMobile) : this.props.intl.formatMessage(messages.filterDescr)}
                </h2>
                {isMobile() && <div className='c-ckm-browsebar__heading-trigger-icon c-ckm-icon c-ckm-icon--arrow-down-blue' />}
                {(isDesktop() || isTablet()) && ((Object.values(this.props.facets).length > 0 || this.props.savedContentSelected) &&
                  <ResetButton onClick={this.resetActiveCheckboxes} disabled={this.hasCheckedFacets() || this.state.savedBooksCheck || this.props.isLoadingContent} />)}
              </div>}
            <div className='u-ckm-visuallyhidden'>{this.props.selectedFiltersAnnouncement}</div>
            {isMobile() && <div className={this.state.isOpen ? 'c-ckm-filter__reset' : 'c-ckm-filter__reset-invisible'}>{(Object.values(this.props.facets).length > 0) &&
              <ResetButton onClick={this.resetActiveCheckboxes} disabled={this.hasCheckedFacets() || this.state.savedBooksCheck || this.props.isLoadingContent} />}</div>}
            {this.props.showSearchWithinSaved && !this.props.isSearchingWithin && !this.props.isSummaries &&
              <div className={this.state.isOpen ? 'c-ckm-filter__saved' : 'c-ckm-filter__saved-visible'}>
                <ELSSlideSwitch
                  className='c-els-slide-switch__input'
                  type='checkbox'
                  id='search-saved-content'
                  name='search-saved-content'
                  size='small'
                  labelDefault={this.props.intl.formatMessage(messages.savedContent)}
                  position='left'
                  value=''
                  checked={this.state.savedBooksCheck}
                  changeHandler={this.handleSavedBooks}
                  tabIndex='0'
                />
              </div>}
            {this.getFilters(width)}
            {this.props.showAtoZ &&
              <AtoZ
                intl={this.props.intl}
                handleLetterSelection={this.props.handleLetterSelection}
                letters={this.props.letters}
                handleLetterClear={this.props.handleLetterClear}
                selectedLetter={this.props.selectedLetter}
                containerWidth={width}
              />}
          </div>}
      />
    );
  }
}

Filters.displayName = 'Filters';
