import React, { Component } from 'react';
import InternalLink from '../../components/common/InternalLink';
import { getPracticeImageThumbnailUrl } from '../../utils/stringUtils';
import { Icon } from '@els/els-react--icon';
import { CASE_STATES } from '../case-player/utils/pjbConstants';
import {getStatusString} from '../case-player/utils/helpers/caseStatusUtils';

export default class CaseSearchResultListItem extends Component {
  handleClick = (eid, position) => (evt) => {
    if (this.props.clickHandler) {
      this.props.clickHandler(eid, position)(evt);
    }
  };

  generateAgeComplaintString = (age, complaint) => {
    if (age && complaint) {
      return `${age}, ${complaint[0]}`;
    }
    if (age) return age;
    if (complaint) return complaint[0];
  };

  render () {
    return (
      <li className='c-ckm-search-results-list__item c-ckm-search-results-list__item--case'>
        <InternalLink
          className='c-ckm-search-results-list__case-wrapper'
          href={`/practice/${this.props.pii}`}
        >
          <div className='c-ckm-search-results-list__case-info'>
            <h3 className='c-ckm-search-results-list__case-title'>
              {this.props.casename}
            </h3>
            <p className='c-ckm-search-results-list__case-age'>
              {this.generateAgeComplaintString(this.props.caseage, this.props.chiefcomplaint)}
            </p>
            {this.props.status && this.props.status !== CASE_STATES.NOT_STARTED &&
              <p className={`c-ckm-search-results-list__case-status-started ${this.props.status === CASE_STATES.COMPLETED ? 'c-ckm-search-results-list__case-status-started--completed' : ''}`}>
                <Icon
                  a11y={{ name: 'Patient chart' }}
                  sprite={this.props.status === CASE_STATES.COMPLETED ? Icon.Sprites.CONFIRMATION_SOLID_CIRCLE : Icon.Sprites.CLOCK}
                  className='u-els-margin-right-1o2'
                  size={Icon.Sizes.XS}
                  id='patient-chart-icon'
                  textAlignment='middle'
                  isVisible
                >
                  {getStatusString(this.props.status, this.props.intl)}
                </Icon>
              </p>}
            <div className='c-ckm-search-results-list__case-description'>
              <p>{this.props.casedescription ? this.props.casedescription : ''}</p>
            </div>
          </div>
          <div className='c-ckm-search-results-list__case-image'>
            <img src={getPracticeImageThumbnailUrl([`SPC/case-images/${this.props.pii}.jpg`])} alt={this.props.casename} />
          </div>
        </InternalLink>
      </li>
    );
  }
}

CaseSearchResultListItem.displayName = 'CaseSearchResultListItem';
