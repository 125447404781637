import {useEffect} from 'react';
import {debounce} from 'lodash';

export const marginTopAnchorTag = 'scroll-margin-top-anchor';
export const marginTopAnchorVisibilityTag = {
  MOBILE_ONLY: 'scroll-margin-top-anchor-mobile-only',
  TABLET_ONLY: 'scroll-margin-top-anchor-tablet-only',
  DESKTOP_ONLY: 'scroll-margin-top-anchor-desktop-only'
};

export const windowBreakpoints = {
  MOBILE: 900,
  TABLET: 1200
};

export const addScrollMarginTop = (elements, marginValue = 0) => {
  if (!elements) {
    return;
  }

  elements.forEach((tag) => {
    const element = document.querySelector(tag);

    if (!element) {
      return;
    }

    element.style.scrollMarginTop = `${marginValue}px`;
  });
};

export const countMarginValue = (anchors, padding) => {
  let result = padding;

  if (!anchors) {
    return result;
  }

  anchors.forEach((anchor) => {
    result += anchor.clientHeight || 0;
  });

  return result;
};

export const getActiveAnchors = () => {
  const anchors = document.querySelectorAll(`.${marginTopAnchorTag}`);
  const result = [];

  if (!anchors) {
    return result;
  }

  anchors.forEach((anchor) => {
    if (
      !anchor.classList.contains(marginTopAnchorVisibilityTag.MOBILE_ONLY) &&
      !anchor.classList.contains(marginTopAnchorVisibilityTag.TABLET_ONLY) &&
      !anchor.classList.contains(marginTopAnchorVisibilityTag.DESKTOP_ONLY)) {
      result.push(anchor);
    }

    if (window.innerWidth < windowBreakpoints.MOBILE && anchor.classList.contains(marginTopAnchorVisibilityTag.MOBILE_ONLY)) {
      result.push(anchor);
    }

    if (window.innerWidth >= windowBreakpoints.MOBILE && window.innerWidth < windowBreakpoints.TABLET && anchor.classList.contains(marginTopAnchorVisibilityTag.TABLET_ONLY)) {
      result.push(anchor);
    }

    if (window.innerWidth >= windowBreakpoints.TABLET && anchor.classList.contains(marginTopAnchorVisibilityTag.DESKTOP_ONLY)) {
      result.push(anchor);
    }
  });

  return result;
};

const useDynamicScrollMarginTop = (props) => {
  const { padding = 0, elements} = props;

  const updateScrollMarginTop = debounce(() => {
    const anchors = getActiveAnchors();

    if (!anchors || !elements) {
      return;
    }

    const scrollMarginTop = countMarginValue(anchors, padding);

    addScrollMarginTop(elements, scrollMarginTop);
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', updateScrollMarginTop);

    return () => {
      window.removeEventListener('resize', updateScrollMarginTop);
    };
  }, []);

  useEffect(() => {
    updateScrollMarginTop();
  }, [elements]);
};

export default useDynamicScrollMarginTop;
