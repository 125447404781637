import React from 'react';
import { Provider } from 'react-redux';
import store from '../../store';

const StoreProvider = (props) => {
  const children = React.Children.map(props.children, child => {
    return React.cloneElement(child, { intl: props.intl });
  });

  return (
    <Provider store={{...store}}>
      {children}
    </Provider>
  );
};

export default StoreProvider;
