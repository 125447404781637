import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {slice} from 'ramda';
import FilterItem from './FilterItem';
import {FormattedMessage, defineMessages} from 'react-intl';
import {removeWhitespace} from '../../utils/stringUtils';
import {isMobile} from '../../utils/elementUtils';
import ResizeDetector from 'react-resize-detector';
import { Button } from '@els/els-react--button';

const TRUNCATED_LENGTH_MOBILE = 5;
const TRUNCATED_LENGTH = 20;

const messages = defineMessages({
  ContentType: {
    id: 'Filters.category.contentType',
    defaultMessage: 'Content type'
  },
  Specialties: {
    id: 'Filters.category.specialties',
    defaultMessage: 'Specialties'
  },
  Language: {
    id: 'Filters.category.language',
    defaultMessage: 'Language'
  },
  FileFormat: {
    id: 'Filters.category.fileFormat',
    defaultMessage: 'File Format'
  },
  SubContentType: {
    id: 'Filters.category.assetType',
    defaultMessage: 'Asset Type'
  },
  ContentLevel: {
    id: 'Filters.category.contentLevel',
    defaultMessage: 'Content level'
  },
  ButtonClose: {
    id: 'FilterSection.button.close',
    defaultMessage: 'Close'
  },
  browseTypeANC: {
    id: 'Browse.type.anc',
    defaultMessage: 'Teaching material'
  },
  browseFiltersShowMoreAccessibleName: {
    id: 'Browse.filters.showMore.AccessibleName',
    defaultMessage: 'Show more {filterHeader} filter items'
  },
  browseFiltersShowLessAccessibleName: {
    id: 'Browse.filters.showLess.AccessibleName',
    defaultMessage: 'Show less {filterHeader} filter items'
  }
});

export default class FilterSection extends Component {
  state = {
    isTruncated: true,
    isMobile: false
  };

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    facetSelected: PropTypes.func.isRequired,
    facets: PropTypes.array.isRequired,
    filterClass: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    sectionName: PropTypes.string.isRequired,
    toggleFilters: PropTypes.func.isRequired,
    lastSection: PropTypes.bool.isRequired,
    checked: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    truncatedLength: PropTypes.number
  };

  // refs for focus management
  itemRef = React.createRef();

  toggleTruncation = (evt) => {
    evt.preventDefault();
    this.setState((prevState) => ({isTruncated: !prevState.isTruncated}), this.focusItem);
    document.activeElement.blur();
  };

  focusItem = () => {
    this.itemRef.current.focus();
  };

  onResize = () => {
    this.setState({ isMobile: isMobile() });
  };

  translateSectionName = (sectionName) => {
    const message = messages[removeWhitespace(sectionName)];

    return message ? this.props.intl.formatMessage(message) : sectionName;
  };

  render () {
    const truncatedLength = this.props.truncatedLength || (this.state.isMobile ? TRUNCATED_LENGTH_MOBILE : TRUNCATED_LENGTH);
    const translatedSectionName = this.translateSectionName(this.props.sectionName);
    const showTruncation = this.props.facets.length > truncatedLength;
    const facetsToDisplay = showTruncation && this.state.isTruncated ? slice(0, truncatedLength, this.props.facets) : this.props.facets;
    const hasAdditionalMaterial = facetsToDisplay.find(type => type.facetquery === '+contenttype:ANC');
    const sortedFacetsToDisplay = facetsToDisplay.sort((a, b) => a.name.trimStart().localeCompare(b.name.trimStart()));

    if (this.props.sectionName === 'Age Category') {
      const itemOrder = ['age:"Pediatric/Adolescent"', 'age:"Adult"', 'age:"Geriatric"'];
      sortedFacetsToDisplay.sort((a, b) => itemOrder.indexOf(a.facetquery) - itemOrder.indexOf(b.facetquery));
    }

    if (this.props.sectionName === 'Difficulty') {
      const itemOrder = ['difficulty:"Easy"', 'difficulty:"Moderate"', 'difficulty:"Hard"'];
      sortedFacetsToDisplay.sort((a, b) => itemOrder.indexOf(a.facetquery) - itemOrder.indexOf(b.facetquery));
    }

    if (hasAdditionalMaterial) {
      hasAdditionalMaterial.name = this.props.intl.formatMessage(messages.browseTypeANC);
    }
    return (
      <ResizeDetector
        handleWidth
        onResize={this.onResize}
        refreshMode='debounce'
        refreshOptions={{trailing: true}}
        render={() =>
          <section className={'c-ckm-filter__section ' + this.props.filterClass}>
            <h3 className='c-ckm-filter__subheading'>
              {translatedSectionName}
            </h3>
            <ul className='c-ckm-filter__list'>
              {sortedFacetsToDisplay.map((facet, i) =>
                <FilterItem
                  key={facet.facetquery}
                  {...facet}
                  sectionName={this.props.sectionName}
                  name={facet.name.trimStart()}
                  facetSelected={this.props.facetSelected}
                  intl={this.props.intl}
                  innerRef={i + 1 === truncatedLength ? this.itemRef : null}
                  checked={facet.isActive}
                  change={this.props.change}
                  isLoadingContent={this.props.isLoadingContent}
                />
              )}
            </ul>
            {showTruncation &&
              <button
                className='c-ckm-link'
                aria-label={
                  this.state.isTruncated
                  ? this.props.intl.formatMessage(messages.browseFiltersShowMoreAccessibleName, {filterHeader: translatedSectionName})
                  : this.props.intl.formatMessage(messages.browseFiltersShowLessAccessibleName, {filterHeader: translatedSectionName})
                }
                onClick={this.toggleTruncation}
              >
                {
                    this.state.isTruncated
                    ? <FormattedMessage id='FilterSectionMore.dropdown.more' defaultMessage='Show more' />
                    : <FormattedMessage id='FilterSectionLess.dropdown.less' defaultMessage='Show less' />
                  }
              </button>}
            {this.props.children ? this.props.children : null}
            {this.props.lastSection &&
              <Button
                type={Button.Types.PRIMARY}
                className='c-ckm-filter__button'
                onClick={this.props.toggleFilters}
                aria-label={this.props.intl.formatMessage(messages.ButtonClose)}
                title={this.props.intl.formatMessage(messages.ButtonClose)}
              >
                {this.props.intl.formatMessage(messages.ButtonClose)}
              </Button>}
          </section>}
      />
    );
  }
}

FilterSection.displayName = 'FilterSection';
