import React, {Component} from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames';
import {defineMessages} from 'react-intl';
import withOutsideEvent from '../../components/common/withOutsideEvent';
import { Icon } from '@els/els-react--icon';

const messages = defineMessages({
  launchBookshelf: {
    id: 'StudyTools.links.launchBookshelf',
    defaultMessage: 'Launch Bookshelf'
  },
  bookshelfInfoHeader: {
    id: 'QuickAction.bookshelfInfoHeader',
    defaultMessage: 'What is Bookshelf?'
  },
  bookshelfInfoBodyP1: {
    id: 'QuickAction.bookshelfInfoBodyP1',
    defaultMessage: 'Bookshelf is a e-textbook reader that allows you to'
  },
  bookshelfInfoBodyP2: {
    id: 'QuickAction.bookshelfInfoBodyP2',
    defaultMessage: 'read online and offline, highlight text, create flashcards, and more.'
  },
  bookshelfInfoBodyP3: {
    id: 'QuickAction.bookshelfInfoBodyP3',
    defaultMessage: 'To open a book in Bookshelf, click the'
  },
  bookshelfInfoBodyP4: {
    id: 'QuickAction.bookshelfInfoBodyP4',
    defaultMessage: 'Add to Bookshelf'
  },
  bookshelfInfoBodyP5: {
    id: 'QuickAction.bookshelfInfoBodyP5',
    defaultMessage: 'button from the book table of contents or any book chapter page.'
  },
  learnMore: {
    id: 'ChooseInstitution.link.learnMore',
    defaultMessage: 'Learn more'
  }
});

class BookshelfInfoDropdown extends Component {
  static propTypes = {
    isOpen: bool
  };

  state = {
    isOpen: false
  };

  handleCloseDropdown = () => {
    this.setState({isOpen: false});
  };

  render () {
    const {className, intl } = this.props;
    const contentContainerClass = classNames(
      'c-ckm-book-details__content-container c-ckm-bookshelf-details-container', {
        'c-ckm-book-details--open': this.state.isOpen
      });

    return (
      <div className={classNames(className, 'c-ckm-book-details')}>
        <button id='q-action-bookshelf-info-btn-icon' className='c-ckm-bookshelf__info-btn' aria-expanded={this.state.isOpen}>
          <Icon
            a11y={{ name: 'info'}}
            sprite={Icon.Sprites.INFORMATION_OUTLINE_CIRCLE}
            id='q-action-bookshelf-info-icon'
            isDecorative
            className='c-ckm-bookshelf__info-icon'
            onClick={() => this.setState(prevState => ({isOpen: !prevState.isOpen}))}
          />
          <div className={contentContainerClass}>
            <div className='o-ckm-content-panel c-ckm-book-details__content c-ckm-bookshelf-details'>
              <Icon
                a11y={{ name: 'Clear search icon' }}
                sprite={Icon.Sprites.CLOSE}
                id='bookshelf-close-icon'
                size={Icon.Sizes.S}
                isDecorative
                className='c-ckm-bookshelf__close-icon'
                onClick={this.handleCloseDropdown}
              />
              <h4>{intl.formatMessage(messages.bookshelfInfoHeader)}</h4>
              <div className='c-ckm-bookshelf-details-body'>
                {intl.formatMessage(messages.bookshelfInfoBodyP1) + ' '}
                <strong>{intl.formatMessage(messages.bookshelfInfoBodyP2)}</strong>
                <p className='c-ckm-bookshelf-details-next-string' />
                {intl.formatMessage(messages.bookshelfInfoBodyP3) + ' '}
                <strong>"{intl.formatMessage(messages.bookshelfInfoBodyP4)}" </strong>
                {intl.formatMessage(messages.bookshelfInfoBodyP5)}
              </div>
              <a className='c-ckm-bookshelf-link' href='https://service.elsevier.com/app/answers/detail/a_id/26002' target='_blank' rel='noreferrer' onClick={(e) => e.stopPropagation()}>
                {intl.formatMessage(messages.learnMore)}
                <svg className='o-els-icon-svg o-els-icon-svg--1x c-ckm-bookshelf-icon-arrow'>
                  <use href='#icon-sprite_els-gizmo-icon-up-right' />
                </svg>
              </a>
            </div>
          </div>
        </button>
      </div>
    );
  }
}

BookshelfInfoDropdown.displayname = 'BookshelfInfoDropdown';
export default withOutsideEvent(BookshelfInfoDropdown);
