import React from 'react';
import { defineMessages } from 'react-intl';
import { Icon } from '@els/els-react--icon';
import { Button } from '@els/els-react--button';
import CommonShareButton from './CommonShareButton';
import SaveToCollectionButton from './SaveToCollectionButton';

const messages = defineMessages({
  shareHeader: {
    id: 'collections.share.header.nnn',
    defaultMessage: 'Share content'
  },
  shareInfo: {
    id: 'collections.share.info.nnn',
    defaultMessage: 'Recipients must have access to ClinicalKey Student in order to view this content.'
  },
  shareEmailSubject: {
    id: 'collections.shareEmail.subject.nnn',
    defaultMessage: 'I would like to share with you this content'
  },
  shareEmailBody: {
    id: 'collections.shareEmail.body.nnn',
    defaultMessage: 'Here is the link to content'
  }
});

const NNNHeaderButtons = (props) => {
  const { eid, defaultCollectionId, collections, sourcetitle, updateCollections, updateDefaultCollectionId, shareLink, headerText } = props;
  return (
    <div className='c-ckm-nnn__button'>
      <div className='c-ckm-nnn__button-save'>
        {props.eid &&
          <SaveToCollectionButton
            eid={eid}
            defaultCollectionId={defaultCollectionId}
            collections={collections}
            sourcetitle={sourcetitle}
            type='multimedia'
            placement='bottom'
            updateCollections={updateCollections}
            updateDefaultCollectionId={updateDefaultCollectionId}
          />}
      </div>
      <div className='c-ckm-nnn__button-share'>
        <CommonShareButton
          id='shared-btn-nnn-secondLevel'
          type={Button.Types.LINK}
          name={headerText}
          url={shareLink}
          iconSize={Icon.Sizes.S}
          showLabel={false}
          preview={{
            title: headerText,
            meta: '',
            picto: 'picto-reference'
          }}
          tabletPositionRight
          className='c-ckm-collections__button-share'
          menuContent={{
            header: messages.shareHeader,
            info: messages.shareInfo,
            emailSubject: messages.shareEmailSubject,
            emailBody: messages.shareEmailBody
          }}
        />
      </div>
    </div>
  );
};

export default NNNHeaderButtons;
