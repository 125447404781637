const {forEachObjIndexed, match, propOr, propEq, nth, compose, test, has, contains, propSatisfies, defaultTo, is} = require('ramda');
const {
  EMULATOR_ACCOUNT, LEARN_ACCESS_FENCE, ANC_ACCESS_FENCE, TEST_ACCESS_FENCE, NNNCONSULT_ACCESS_FENCE, PRESENTATION_ACCESS_FENCE, SUPPRESS_REG_INPUT_FENCE, PRACTICE_ACCESS_FENCE, CKMEDED_IND_SHOWLIST, INSTRUCTOR_ROLE
} = require('../config/constants');

const INSTRUCTOR_ROLES = [
  INSTRUCTOR_ROLE.DOCTOR,
  INSTRUCTOR_ROLE.FACULTY,
  INSTRUCTOR_ROLE.LIBRARIAN,
  INSTRUCTOR_ROLE.RESEARCHER,
  INSTRUCTOR_ROLE.TEACHER,
  INSTRUCTOR_ROLE.OTHER,
  INSTRUCTOR_ROLE.RESEARCH_ADMINISTRATOR,
  INSTRUCTOR_ROLE.CEO_CTO_CSO,
  INSTRUCTOR_ROLE.RND_MANAGER_TECHNOLOGY_LEAD,
  INSTRUCTOR_ROLE.CONSULTANT,
  INSTRUCTOR_ROLE.PROFESSOR,
  INSTRUCTOR_ROLE.BUSINESS_DEVELOPMENT_MANAGER,
  INSTRUCTOR_ROLE.SOFTWARE_DEVELOPER,
  INSTRUCTOR_ROLE.CIO,
  INSTRUCTOR_ROLE.CMO_CMIO,
  INSTRUCTOR_ROLE.DOCTOR_CONSULTANT,
  INSTRUCTOR_ROLE.DOCTOR_INTERN,
  INSTRUCTOR_ROLE.DOCTOR_MEDICAL_FELLOW,
  INSTRUCTOR_ROLE.DOCTOR_PHYSICIAN_ASSISTANT,
  INSTRUCTOR_ROLE.DOCTOR_RESIDENT_LEARNING_PHYSICIAN,
  INSTRUCTOR_ROLE.DOCTOR_SURGEON,
  INSTRUCTOR_ROLE.NURSE,
  INSTRUCTOR_ROLE.NURSE_PRESCRIBING_NURSE,
  INSTRUCTOR_ROLE.PROFESSOR_ASSOCIATE_PROFESSOR,
  INSTRUCTOR_ROLE.LECTURER
];

/**
 * @typedef Features
 * @type {object}
 * @property {boolean} name of feature based on Feature Entitlement API
 */

const accessTypeRegex = /(?:\|)(\w*),ACCESS_TYPE\)$/;

module.exports = class User {
  constructor (sessionData = {}) {
    this.features = {};
    this.search_indexes = [];
    this.userRoleName = '';

    forEachObjIndexed((value, key) => {
      if (!is(Function, value)) {
        this[key] = value;
      }
    }, sessionData);
  }

  /**
   * Returns all non-function properties of the user object
   * @param {Boolean} toString determines if output is serialized to a string via JSON.stringify
   * @returns {Object | String}
   */
  serialize = (toString = false) => {
    const nonFunctionProperties = {};

    forEachObjIndexed((value, key) => {
      if (!is(Function, value)) {
        nonFunctionProperties[key] = value;
      }
    }, this);
    return toString ? JSON.stringify(nonFunctionProperties) : nonFunctionProperties;
  };

  /** Basic Getters */

  /**
   * @param {String} propertyName name of user property
   * @returns {object | string[] | string | number  | boolean | Features} value of the given property
   */
  get = (propertyName) => this[propertyName];

  /**
   * @returns {Features} map of available features as boolean values (based on CID entitlement)
   */
  getFeatures = () => propOr({}, 'features', this);

  /**
   * @returns {string[]} array of search index string identifiers
   */
  getSearchIndexes = () => propOr([], 'search_indexes', this);

  /**
   * @returns {string} usage_path_info string
   */
  getUsagePathInfo = () => propOr('', 'usage_path_info', this);

  /**
   * Returns user access type - ANON_IP, REG_SHIBBOLETH, etc
   * @returns {string} access type
   */
  getAccessType = compose(
    nth(1),
    match(accessTypeRegex),
    this.getUsagePathInfo
  );

  /**
   * @returns {string} first non-GLOBAL index. If no index is found, defaults to GLOBAL
   */
  getDefaultIndex = () => this.getSearchIndexes().find((index) => index !== 'GLOBAL') || 'GLOBAL';

  /** Basic Setters */

  /**
   * @param {string} propertyName name of property to set
   * @param {*} value value of property to set
   */
  set = (propertyName, value) => {
    this[propertyName] = value;
  };

  /**
   * @param {Features} features key/boolean map of features
   */
  setFeatures = (features) => {
    this.set('features', features);
  };

  /**
   * @param {string} userRoleName name of the User role to set
   */
  setUserRoleName = (userRoleName) => {
    this.set('userRoleName', userRoleName);
  };

  /**
   * @param {string[]} indexes array of search index string identifiers
   */
  setSearchIndexes = (indexes) => {
    this.set('search_indexes', indexes);
  };

  /** User Types */

  /**
   * @returns {boolean} indicator if user is currently in the Path Choice (Choose org) step
   */
  isPathChoice = () => propEq('status', 'PATH_CHOICE', this);

  /**
   * @returns {boolean} indicator if user is anonymously IP authenticated
   */
  isIPAuth = compose(
    test(/^ANON_IP/),
    this.getAccessType
  );

  /**
   * @returns {boolean} indicator if user is a registered individual, regardless of authentication type
   */
  isIndividual = compose(
    test(/^REG/),
    this.getAccessType
  );

  /**
   * @returns {boolean} indicator if user is an anonymous Shibboleth (SAML) user who is allowed to register
   */
  isRegisterableShibboleth = () => propEq('allowed_registration_type', 'SHIBBOLETH', this);

  /**
   * @returns {boolean} indicator if user is an anonymous TicURL user who is allowed to register
   */
  isRegisterableTicUrl = () => propEq('allowed_registration_type', 'TICURL', this);

  /**
   * @returns {boolean} indicator if user is an anonymous Shibboleth (SAML) user
   */
  isAnonShibboleth = compose(
    test(/^ANON_SHIB/),
    this.getAccessType
  );

  /**
   * @returns {boolean} indicator if user is an anonymous TicURL user
   */
  isAnonTicUrl = compose(
    test(/^ANON_TICURL/),
    this.getAccessType
  );

  /**
   * @returns {boolean} indicator if user is anonymous guest
   */
  isAnonGuest = compose(
    test(/^ANON_GUEST/),
    this.getAccessType
  );

  /**
   * @returns {boolean} indicator if user is allowed to register, regardless of authentication type
   */
  canRegister = () => has('allowed_registration_type', this) && !propEq('allowed_registration_type', 'GUEST', this);

  /**
   * @returns {boolean} indicator if user account is the CSAS Offline placeholder account (indicates A&E outage)
   */
  isEmulatorAccount = () => this.account_id === EMULATOR_ACCOUNT.account_id;

  /** Feature & Fence Checking */

  /**
   * @param {string} fence name to check
   * @returns {boolean} indicator if user has the provided fence
   */
  hasFence = fence => propSatisfies(
    compose(
      contains(fence),
      defaultTo([])
    ),
    'fences'
  )(this);

  /**
   * @param {string} feature name to check
   * @returns {boolean} indicator if user has the provided feature
   */
  hasFeature = feature => propOr(false, feature, this.features);

  /**
   *
   * @param {string} indexName search index value
   * @returns {boolean} indicator if user has the provided search index
   */
  hasSearchIndex = indexName => contains(indexName, this.search_indexes);

  /**
   * @returns {boolean} indicator if user has access to Foundation product
   */
  hasFoundationAccess = () => {
    return this.hasFence(LEARN_ACCESS_FENCE) || this.hasFeature('cksFoundationAccess');
  };

  /**
 * @returns {boolean} indicator if user only has only Foundation access
 */
  hasFoundationOnlyAccess = () => {
    return this.hasFoundationAccess() && !this.hasAssessmentAccess() && !this.hasPracticeAccess();
  };

  /**
   * @returns {boolean} indicator if user has access to Assessment product
   */
  hasAssessmentAccess = () => {
    return this.hasFence(TEST_ACCESS_FENCE) || this.hasFeature('cksAssessmentAccess');
  };

  /**
   * @returns {boolean} indicator if user has access to Clinical Cases (Practice) product
   */
  hasPracticeAccess = () => {
    return this.hasFence(PRACTICE_ACCESS_FENCE) || this.hasFeature('cksPracticeAccess');
  };

  /**
   * @returns {boolean} indicator if user only has access to Clinical Cases (Practice) product
   */
  hasPracticeOnlyAccess = () => {
    return this.hasPracticeAccess() && !this.hasAssessmentAccess() && !this.hasFoundationAccess();
  };

  /**
   * @returns {boolean} indicator if user has Instructor role
   */
  hasInstructorRole = () => {
    return contains(this.userRoleName, INSTRUCTOR_ROLES);
  };

  // Flag used in Learning hub router which helps us to select appropriate /student route
  // component within IA Experience (LearningHub/Foundation home/CC home/Assessment home)
  hasMoreThanOneApp = () => {
    const accesses = [
      this.hasFoundationAccess(),
      this.hasAssessmentAccess(),
      this.hasPracticeAccess(),
      this.hasClinicalSkillsAccess()
    ];
    const hasMoreThanOneApp = (accesses.filter(item => item === true)).length > 1;
    return hasMoreThanOneApp;
  };

  /**
   * @returns {boolean} indicator if user has access to CA product
   */
  hasCompleteAnatomyAccess = () => {
    return this.hasFeature('completeAnatomyAccess');
  };

  /**
   * @returns {boolean} indicator if user has access to Osmosis product
   */
  hasOsmosisAccess = () => {
    return this.hasFeature('osmosisAccess');
  };

  /**
   *
   * @returns {boolean} indicator if user has access to EMC content (FRENCH only)
   */
  hasJournalAccess = (productContext) => this.getDefaultIndex() === 'FRENCH' && productContext === 'nursing';

  /**
   *
   * @returns {boolean}indicator if user has access to Quick Access Summaries
   */
  hasSummariesAccess = (productContext) => {
    return this.getDefaultIndex() !== 'GERMAN' && productContext !== 'nursing';
  };

  /**
   *
   * @returns {boolean} indicator if user has access to EMC content (FRENCH only)
   */
  hasEMCAccess = () => this.getDefaultIndex() === 'FRENCH';

  /**
   * @returns {boolean} indicator if user has access to Nursing Instructor Ancillary content (ANC)
   */
  hasANCAccess = (productContext) => {
    return productContext === 'nursing' && (this.hasFence(ANC_ACCESS_FENCE) || this.hasFeature('cksANCContent'));
  };

  /**
   * @returns {boolean} indicator if user has access to NNNConsult content (Nursing only)
   */
  hasNNNConsultAccess = () => {
    return this.hasFence(NNNCONSULT_ACCESS_FENCE) || this.hasFeature('cksNNNConsultContent');
  };

  /**
   * @returns {boolean} indicator if user only has access to NNNConsult content and nothing else
   */
  isNNNOnly = () => {
    return this.hasNNNConsultAccess() && !this.hasFeature('cksFoundationAccess');
  };

  /**
 * @returns {boolean} indicator if user has access to Clinical Skills content
 */
  hasClinicalSkillsAccess = () => this.hasFeature('ecsAccess');

  /**
   * @returns {boolean} indicator if user has access to Presentations
   */
  hasPresentationAccess = () => this.hasFence(PRESENTATION_ACCESS_FENCE);

  /**
   * @returns {boolean} indicator if registration page should hide input fields (Egypt only)
   */
  shouldSuppressRegistrationInput = () => this.hasFence(SUPPRESS_REG_INPUT_FENCE);

  /**
   * @returns {boolean} indicator if user has access to Indian Clinical Videos (India only)
   */
  hasIndianVideoAccess = () => this.hasFence(CKMEDED_IND_SHOWLIST);

  /**
  * @returns {boolean} indicator if user was logged in via federate access
   */
  isShibbolethLogin = () => Boolean(propOr('', 'shibboleth_attributes', this));
};
