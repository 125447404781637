import React, { useCallback, useState, useRef, useEffect } from 'react';
import { oneOf, PropTypes } from 'prop-types';
import ResizeDetector from 'react-resize-detector';
import classNames from 'classnames';
import { useIntl } from '../../hooks';
import HTMLString from './HTMLString';
import { debounce } from 'lodash';

const CONTAINER_HEIGHT = 25;
const ROWS = 2;

const messages = {
  showMoreButtonText: {
    id: 'TruncatedText.showMoreButtonText',
    defaultMessage: 'Show all'
  },
  showLessButtonText: {
    id: 'TruncatedText.showLessButtonText',
    defaultMessage: 'Show less'
  },
  showMoreText: {
    id: 'TruncatedText.showMoreText',
    defaultMessage: 'show more'
  }
};

const componentClassNames = {
  truncatedTextContent: 'truncated-text__content'
};

const TruncatedText = (props) => {
  const {text, showMoreText, showLessText, type, maxLength, isAlertContainer} = props;
  const [isTruncated, setIsTruncated] = useState(true);
  const [isOverflow, setIsOverflow] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [approxCharCount, setApproxCharCount] = useState(0);

  const componentRef = useRef(null);

  const getNumberOfChar = () => {
    const containerWidth = componentRef.current?.clientWidth;
    const charsPerLine = Math.floor(containerWidth / 8);
    if (isAlertContainer && (charsPerLine * ROWS > maxLength)) {
      return maxLength;
    }
    return charsPerLine * ROWS;
  };

  const truncatedTextClass = classNames(
    'truncated-text',
    { 'truncated-text__truncated': isTruncated }
  );
  const truncatedTextButtonClass = classNames(
    'truncated-text__button',
    { 'truncated-text__button_more': isOverflow && isTruncated }
  );
  const intl = useIntl();

  const handleOnButtonClick = useCallback(() => {
    setIsTruncated(!isTruncated);
  });

  const handleResize = () => {
    if (componentRef.current) {
      const contentHeight = componentRef.current.scrollHeight;

      setIsOverflow(contentHeight > CONTAINER_HEIGHT);
    }
  };

  const getButtonText = () => {
    if (isTruncated) {
      return showMoreText || intl.formatMessage(messages.showMoreButtonText);
    }

    return showLessText || intl.formatMessage(messages.showLessButtonText);
  };

  /**
   *
   * @param {string} text
   */
  const getTextWithoutHTMLTags = (text) => {
    // Regex to select not closed html tags
    const declaredTags = [...text.matchAll(/<\w+/g)];
    if (declaredTags.length === 0) {
      return text;
    }

    const htmlTagReccurentRegex = /<(\w+)[^>]*>.*?<\/\1>/g;
    const closedTags = [...text.matchAll(htmlTagReccurentRegex)];

    const latestNotClosedTag = declaredTags.pop();

    if (closedTags.length === 0) {
      return text.slice(0, latestNotClosedTag.index);
    }

    const latestClosedTag = closedTags.pop();

    if (latestNotClosedTag.index !== latestClosedTag.index) {
      return text.slice(0, latestNotClosedTag.index);
    }

    return text;
  };

  /**
   *
   * @param {string} text
   */
  const clearEndOfShortenedText = (text) => {
    if (text.endsWith('(') || text.endsWith('[') || text.endsWith('{')) {
      return text.slice(0, -1);
    }
    return text;
  };

  useEffect(() => {
    if (componentRef.current && type === 'line') {
      setApproxCharCount(getNumberOfChar());

      const onResize = debounce(() => {
        setApproxCharCount(getNumberOfChar());
      }, 300);

      window.addEventListener('resize', onResize);

      return () => {
        window.removeEventListener('resize', onResize);
        onResize.cancel();
      };
    }
  }, [componentRef.current?.clientWidth, type]);

  if (type === 'line') {
    const maxLengthStr = maxLength ? Number(maxLength) : Number(approxCharCount);
    const shouldTruncate = text.length > maxLengthStr + intl.formatMessage(messages.showLessButtonText).length + 3;
    const shortenedText = clearEndOfShortenedText(getTextWithoutHTMLTags(text.slice(0, +approxCharCount))) + '...';
    const displayText = isExpanded || !shouldTruncate ? text + ' ' : shortenedText;

    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
      <div ref={componentRef}>
        <HTMLString content={displayText} />
        {shouldTruncate && (
          <button className='show-more-btn' onClick={toggleExpand}>
            {isExpanded ? intl.formatMessage(messages.showLessButtonText).toLowerCase() : intl.formatMessage(messages.showMoreText)}
          </button>
        )}
      </div>
    );
  }

  return (
    <ResizeDetector
      handleWidth
      onResize={handleResize}
      refreshMode='debounce'
      refreshRate={100}
      refreshOptions={{trailing: true}}
      render={() =>
        <div className={truncatedTextClass} ref={componentRef}>
          <div className={componentClassNames.truncatedTextContent}>{text}</div>
          {isOverflow &&
            <button className={truncatedTextButtonClass} onClick={handleOnButtonClick}>
              {getButtonText()}
            </button>}
        </div>}
    />
  );
};

TruncatedText.propTypes = {
  text: PropTypes.string.isRequired,
  showMoreText: PropTypes.string,
  showLessText: PropTypes.string,
  type: oneOf(['line']), // The line parameter enables the mode in which the line is trimmed by the number of characters (maxLength) or by the number of lines (ROWS = 2 by default) if maxLength is not specified , and adds a ‘Show more/show less’ button.
  maxLength: PropTypes.number
};

export default TruncatedText;
