import React, { Component } from 'react';
import { defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { TweenLite, Power1 } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin'; //eslint-disable-line

const messages = defineMessages({
  scrollHandlerReturnToTop: {
    id: 'ScrollHandler.aria.returnToTop',
    defaultMessage: 'Return to top'
  }
});

export default class ScrollHandler extends Component {
  state = {
    pageTop: true
  };

  static propTypes = {
    intl: PropTypes.object.isRequired
  };

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const html = document.scrollingElement;

    if (html.scrollTop <= 0) {
      this.setState({ pageTop: true });
    } else {
      this.setState({ pageTop: false });
    }
  };

  scrollToTop = (evt) => {
    evt.preventDefault();
    TweenLite.to(window, 0.75, { scrollTo: { y: 0, autoKill: false }, ease: Power1.easeOut, onComplete: () => this.setFocusOnId('main-content') });
  };

  setFocusOnId = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.setAttribute('tabindex', '-1');
      element.focus({
        preventScroll: true
      });
    }
  };

  render () {
    return (
      <div>
        {!this.state.pageTop &&
          <a
            href='#main-content'
            aria-label={this.props.intl.formatMessage(messages.scrollHandlerReturnToTop)}
            onClick={this.scrollToTop}
            className='c-ckm-return-top'
          >
            <span className='c-ckm-icon c-ckm-icon--arrow-up' />
          </a>}
      </div>
    );
  }
}

ScrollHandler.displayName = 'ScrollHandler';
