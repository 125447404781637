import React, {Fragment, useMemo} from 'react';
import { string, bool, array, object, func } from 'prop-types';
import parse, { domToReact } from 'html-react-parser';
import DOMPurify from 'dompurify';
import { find, propEq } from 'ramda';
import StableUniqueId from 'react-stable-uniqueid';
import useIntl from '../../hooks/use-intl';
import HTMLString from '../../components/common/HTMLString';
import DeepLinkGenerator from '../../components/common/DeepLinkGenerator';
import SectionButtons from './SectionButtons';
import Image from './Image';
import SaveToCollectionButton from '../../components/common/SaveToCollectionButton';
import FeatureOn from '../../components/feature-flag/FeatureOn';

const HTMLContent = ({ bookSections, completeAnatomyScreensData, showSearchResults, query, contentType, title, sections, hideShareLinks, showSectionButtons, selectedSections, sectionButtonHandler, showModelRef, hideModelRef, toggleModelLabels, downloadCAScreens, initCompleteAnatomy, returnScreenLabelViews, updateLabelViewRef, hubEid, eid, defaultCollectionId, collections, updateCollections, updateDefaultCollectionId, nestedSections, isLazyLoadImages }) => {
  const intl = useIntl();

  const parseOptions = {
    trim: true,
    replace: (domNode) => {
      if (showSearchResults && domNode.type === 'text') {
        const queryRegEx = new RegExp(query, 'ig');

        if (domNode.data.match(queryRegEx)) {
          return (
            <StableUniqueId render={({ uniqueId }) => {
              const highlightedText = domNode.data.replace(
                queryRegEx,
                match => `<span id="highlight-${uniqueId}" class="u-els-background-color-extended-yellow-6 c-ckm-reader-content--highlighted">${match}</span>`
              );
              return <HTMLString content={DOMPurify.sanitize(highlightedText, { USE_PROFILES: { html: true } })} />;
            }}
            />
          );
        }
      }

      if (domNode.name === 'button' && domNode.attribs['data-image-zoom']) {
        const imageTag = find(propEq('name', 'img'), domNode.children);
        const screenData = completeAnatomyScreensData?.find((image) => image.imageId === imageTag.attribs.id)?.screens;

        return <Image isLazyLoadImages={isLazyLoadImages} {...imageTag.attribs} screenData={screenData} showModelRef={showModelRef} hideModelRef={hideModelRef} toggleModelLabels={toggleModelLabels} downloadCAScreens={downloadCAScreens} initCompleteAnatomy={initCompleteAnatomy} returnScreenLabelViews={returnScreenLabelViews} updateLabelViewRef={updateLabelViewRef} hubEid={hubEid} />;
      }

      if (!domNode.attribs) {
        return;
      }

      const sectionId = domNode.attribs['data-section-link-id'];
      const sectionTitle = domNode.attribs['data-section-link-description'];

      if (sectionId && sections.includes(sectionId) && !hideShareLinks) {
        const props = { ...domNode.attribs };

        if (props.class) {
          props.className = domNode.attribs.class;
          delete props.class;
        }

        const isShowButtonsForSection = !domNode.attribs.class?.includes('section-label');

        const isShowButton = () => {
          if (selectedSections.includes(sectionId)) return true;
          return !Object.values(nestedSections).flat().includes(sectionId);
        };

        return (
          <div className='u-ckm-clear' data-testid='TST'>
            {isShowButtonsForSection &&
            (showSectionButtons && isShowButton() &&
              <SectionButtons
                buttonHandler={sectionButtonHandler}
                sectionId={sectionId}
                isSelected={selectedSections.includes(sectionId)}
              />
            )}
            {!showSectionButtons &&
              <div className='u-ckm-clear__share'>
                <FeatureOn featureName='collections'>
                  <SaveToCollectionButton defaultCollectionId={defaultCollectionId} collections={collections} type='section' placement='top' sourcetitle={title} sectionTitle={sectionTitle} eid={eid} sectionId={sectionId} updateCollections={updateCollections} updateDefaultCollectionId={updateDefaultCollectionId} />
                </FeatureOn>
                <DeepLinkGenerator
                  type='section'
                  linkDescrip={`${intl.formatMessage(contentType)}: ${title}: ${sectionTitle}`}
                  sectionId={sectionId}
                />
              </div>}
            {React.createElement(domNode.name, { ...props }, domToReact(domNode.children, parseOptions))}
          </div>
        );
      }
    }
  };

  return useMemo(() =>
    bookSections?.map(({ id, content }) =>
      <Fragment key={id}>
        {parse(content, parseOptions)}
      </Fragment>

    ), [bookSections, showSearchResults, completeAnatomyScreensData, query, collections, defaultCollectionId, nestedSections]);
};

HTMLContent.propTypes = {
  bookSections: array,
  query: string,
  showSearchResults: bool,
  contentType: object,
  title: string,
  sections: array,
  selectedSections: array,
  hideShareLinks: bool,
  showSectionButtons: bool,
  sectionButtonHandler: func,
  showModelRef: func,
  hubEid: string
};

HTMLContent.defaultProps = {
  selectedSections: []
};

HTMLContent.displayName = 'HTMLContent';
export default HTMLContent;
